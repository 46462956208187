.examContainerLoaderMobile{
    display: none;
}

@media (max-width: 576px) {
    .examContainerLoaderMobile{
        display: block;
    }    
    .examContainerLoaderDesktop{
        display: none;
    }    
}