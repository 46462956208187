.paymentGatewayView .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px 10px 0px;
}

.paymentGatewayView .header p {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 0px;
	visibility: hidden;
}

.paymentGatewayView .header button {
	background: transparent;
	border: 0px;
	color: #d2d2d2;
	font-size: 22px;
	font-weight: 600;
	height: fit-content;
}

.paymentGatewayView .content {
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.paymentGatewayView .content .contentInnerContainer {
	border: 1px solid #b9b9b9;
	border-radius: 10px;
	padding: 10px 15px;
	margin-bottom: 15px;
	cursor: pointer;
}

.paymentGatewayView .content .error {
	margin-bottom: 6px;
	font-size: 13px;
	font-weight: 500;
	color: darkred;
}

.paymentGatewayView .content .heading {
	margin-bottom: 0px;
	font-size: 17px;
	font-weight: 500;
}

.paymentGatewayView .content .note {
	color: #008000;
	font-size: 11px;
	font-weight: 600;
	margin-bottom: 0px;
}

.paymentGatewayView .content .processedContainer {
	margin-top: 9px;
	margin-bottom: 4px;
}

.paymentGatewayView .content .processedContainer span {
	font-weight: 400;
	font-size: 14px;
	margin-right: 6px;
}

.paymentGatewayView .content .processedContainer img {
	width: 58px;
}

.paymentGatewayView .content .tncContainer {
	font-size: 11px;
	font-weight: 400;
}

.paymentGatewayView .actionBtn {
	border-radius: 5px;
	width: 100%;
	background: #1573fe;
	border: 0px;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: -0.15px;
	padding: 6px 10px;
	margin-top: 10px;
}

.paymentGatewayView .actionBtn img {
	width: 16px;
	margin-left: 8px;
}

/* START : style for desktop */
@media (min-width: 768px) {
	.paymentGatewayView .header {
		display: none;
	}
	.paymentGatewayView .actionBtn {
		position: fixed;
		bottom: 10px;
		width: 370px;
	}
}

/* END : style for desktop */

@media (max-width: 768px) {
	.paymentGatewayView .header {
		display: none;
	}
}
