.tosterViewContainer{
    padding: 6px 11px;
    width: fit-content;
    border-radius: 6px;
    position: fixed;
    right: 40px;
}
.tosterViewContainer p{
    font-size: 17px;
    margin: 0px;
}