.courseTabMobileContainer{
    z-index: 1;
    position: relative;
}
.timelineMaincontainer {
    background: #F4F4F4;
}

.timelineMaincontainer .dtopHeadingContainer {
    background: white;
}

.timelineMaincontainer .timelineSpaceContainer,
.timelineMaincontainer .itopHeadingContainer {
    margin: 0rem 10vw;
    max-width: 1340px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.timelineContentContainer {
    display: block !important;
}


.timelineMaincontainer .dtopHeadingContainer .itopHeadingContainer img {
    width: 18px;
    cursor: pointer;
}

.timelineMaincontainer .dtopHeadingContainer .itopHeadingContainer p {
    margin: 7px 0px;
    color: #414141;
    font-size: 17px;
}


.timelineMaincontainer .timelineContentContainer .contentMainContainer .contentMainContainer_o1 {
    width: 66%;
    margin-left: 35px;
}

.timelineMaincontainer .timelineContentContainer .contentMainContainer .contentMainContainer_o2 {
    width: 30%;
}


@media (min-width: 768px) {
    .timelineMaincontainer .timelineContentContainer .contentMainContainer {
        /* --bs-gutter-x:0px !important; */
        margin-top: 30px;
        padding-bottom: 30px;
    }
}

@media (max-width: 1340px) {
    .timelineMaincontainer .timelineSpaceContainer {
        margin: 0rem 2rem;
    }

    .timelineMaincontainer .itopHeadingContainer {
        margin: 0rem 2rem;
    }
}

@media (max-width: 992px) {
    .timelineMaincontainer .timelineContentContainer .contentMainContainer .contentMainContainer_o1 {
        width: 61%;
    }

    .timelineMaincontainer .timelineContentContainer .contentMainContainer .contentMainContainer_o2 {
        width: 34%;
    }
}


@media (max-width: 768px) {
    .timelineMaincontainer .timelineContentContainer .contentMainContainer .contentMainContainer_o1 {
        width: -webkit-fill-available;
    }

    .timelineMaincontainer .timelineSpaceContainer {
        margin: 1rem 1rem 0rem 1rem;
    }

    .timelineMaincontainer .itopHeadingContainer {
        margin: 0rem 0px;
        padding-left: 10px;
    }
    .timelineMaincontainer .dtopHeadingContainer {
        border-bottom: 1px solid #0073e6;
    }
}

@media (max-width: 576px) {
    .timelineMaincontainer .timelineSpaceContainer {
        margin: 0rem 0rem 0rem 0rem;
    }

    .timelineMaincontainer .itopHeadingContainer {
        margin: 0rem 0px;
    }
}