#disaplyImgModal .modal-header,
#disaplyImgModal .modal-footer {
    border: 0px;
}

#disaplyImgModal .modal-body{
    text-align: center;
}

#disaplyImgModal .modal-body .cropPreview{
    width: 100%;
}


#disaplyImgModal .modal-footer button{
    font-size: 14px;
}

#disaplyImgModal .modal-title {
    color: #000;
    font-size: 15px;
    font-weight: 400;
}