.customRowFixer{
    margin: 0px;
}


/* /////////////// SHINE EFFECT  ///////////////// */

.shineOnCourseCard {
    position: relative;
    overflow: hidden;
    display: block;
    /* display: inline-block; */
}

.shineOnCourseCard img {
    display: block;
    width: 100%;
    height: auto;
}

.shineOnCourseCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: -102%;
    width: 80%;
    /* Set the width to cover the entire container */
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.7) 100%);
    transform: skewX(-25deg);
    z-index: 2;
}

.shineOnCourseCard:hover::before,
.shineOnCourseCard:focus::before {
    animation: shineOnCourseCard 0.9s;
}

@keyframes shineOnCourseCard {
    to {
        left: 170%;
        /* Change from 125% to 100% to cover the entire container */
    }
}

/* /////////////// SHINE EFFECT  ///////////////// */
