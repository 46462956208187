.profileTabC .formContainer {
    padding: 0px !important;
}

.profileTabC .fHeadingContainer {
    padding: 0px !important;
}

.profileTabC .fHeadingContainer .fHeadingContainer_1 .fHeading {
    font-size: 16px !important;
}

.profileTabC .fHeadingContainer .fHeadingContainer_1 .fPara,
.profileTabC .formContainer .fPara {
    font-size: 14px !important;
}

.profileTabC .fPara{
    color: #bbbbbb;
}

.profileTabC .formContainer .inputContainerSelectTagTypeB .inputLable,
.profileTabC .formContainer .fileInputMainContainer .heading {
    font-size: 14px !important;
}

.profileTabC .formContainer .fileInputMainContainer .heading .profileTabCAsterisk{
    margin-right: 6px;
}

.profileTabC .formContainer .inputContainerSelectTagTypeB .selectTag {
    padding: 6px !important;
}

.profileTabC .formContainer .inputContainer_x .inputLable {
    font-size: 14px !important;
}

.profileTabC .formContainer .fileInputMainContainer button {
    padding: 5px 6px !important;
    font-size: 14px !important;
}

.profileTabC .formContainer .inputContainer_x .inputLableCheckBox_span {
    font-size: 14px !important;
}



@media (max-width: 576px) {
    .profileTabC .fHeadingContainer .fHeadingContainer_1 .fHeading {
        font-size: 15px !important;
    }

    .profileTabC .fHeadingContainer .fHeadingContainer_1 .fPara,
    .profileTabC .formContainer .fPara {
        font-size: 14px !important;
    }

    .profileTabC .formContainer .inputContainerSelectTagTypeB .inputLable,
    .profileTabC .formContainer .fileInputMainContainer .heading {
        font-size: 14px !important;
    }

    .profileTabC .formContainer .inputContainer_x .inputLable {
        font-size: 13px !important;
    }

    .profileTabC .formContainer .fileInputMainContainer button {
        font-size: 13px !important;
    }

    .profileTabC .formContainer .inputContainer_x .inputLableCheckBox_span {
        font-size: 13px !important;
    }

}