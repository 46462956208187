.coursePageSupportCardView {
	padding: 12px;
	margin: 10px 0px;
	border-radius: 10px;
	background: #fff;
	border: 1px solid #e9e9e9;
}

.coursePageSupportCardView a {
	color: unset;
	text-decoration: none;
}

.coursePageSupportCardView .topHeading {
	color: #252525;
	font-size: 15px;
	font-weight: 500;
}

.coursePageSupportCardView .r1 {
	align-items: center;
	cursor: pointer;
}

.coursePageSupportCardView .r1 .qMark {
	width: 45px;
}

.coursePageSupportCardView .r2 .heading {
	color: #252525;
	font-size: 15px;
	font-weight: 500;
}

.coursePageSupportCardView .r2 .r2_1 {
	margin-bottom: 7px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.coursePageSupportCardView .r2 .r2_1 img {
	width: 8px;
	float: right;
	margin-right: 5px;
	margin-top: -8px;
}

.coursePageSupportCardView .r2 .r2_1 span {
	color: #7c7e85;
	font-size: 13px;
	font-weight: 400;
}

@media (max-width: 768px) {
	.coursePageSupportCardView {
		width: 100%;
	}

	.coursePageSupportCardView {
		display: none;
	}
}

@media (max-width: 576px) {
	.coursePageSupportCardView {
		border-radius: 0px;
	}
}
