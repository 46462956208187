/* START : login main container common style */
.loginMainContainer {
    border: 1px solid #DADCE0;
    border-radius: 10px;
    width: 450px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 55%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px 25px;

    /* border: 1px solid black; */
    /* background-color: pink; */
}

.loginMainContainer .headerLogo {
    width: 50px;
    margin-bottom: 10px;
}

.loginMainContainer .header {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.loginMainContainer .subHeader {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #5A5A5A;
    margin-bottom: 40px;
}

.loginMainContainer .error{
    color: #a42020;
    font-size: 14px;
    font-weight: 500;
}

.loginMainContainer .nextBtn{
    float: right;
}
/* END : login main container common style */




/* START : login style */
.loginMainContainer .tnCContent {
    font-size: 14px;
    margin: 70px 0px;
}

.loginMainContainer .loginBtn {
    background: #1A73E8;
    color: white;
    border: 0px;
    border-radius: 5px;
    padding: 3px 13px;
    float: right;
    /* color: #1A73E8;
    border: 0px;
    background: transparent;
    font-weight: 500; */
}
.loginMainContainer .loginBtn:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}
/* START : login style */



/* START : tag compoenet style override */
.tagsComponent {
    /* background: transparent !important; */
    /* border: 0px !important; */
}

.tagsComponent img {
    /* display: none */
}

.tagsComponent span {
    font-size: 13px !important;
    /* margin-bottom: 7px !important; */
}

/* END : tag compoenet style override */



/* START : otp style override */
.loginMainContainer .otpMainContainer .otpError {
    margin-bottom: 7px;
    color: #962020;
    font-size: 14px;
    text-align: center;
}

.loginMainContainer .otpMainContainer .otpInputContainer {
    display: flex;
    justify-content: space-around;
}

.loginMainContainer .otpMainContainer .otpInputContainer .otp {
    width: 45px;
    height: 56px;
    margin-right: 12px;
    font-size: 23px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #DADCE0;
}

.loginMainContainer .otpMainContainer .resendOtpBtn {
    float: right;
    background: transparent;
    border: 0px;
    text-decoration: underline;
    padding: 0px;
    color: #1A73E8;
    font-size: 15px;
    font-weight: 500;
}

.loginMainContainer .otpMainContainer .resendText {
    text-align: right;
    width: 100%;
    margin-top: 5px;
    display: inline-block;
}

.loginMainContainer .otpMainContainer .tnc {
    font-size: 14px;
    margin: 30px 0px;
    display: inline-block;
}

.loginMainContainer .otpMainContainer .otpSubmitBtnContainer {
    float: right;
}

.loginMainContainer .otpMainContainer .otpSubmitBtnContainer .otpSubmitBtn,
.loginMainContainer .nextBtn {
    background: #1A73E8;
    color: white;
    border: 0px;
    border-radius: 5px;
    padding: 3px 13px;
}
/* END : otp style override */



/* START : legal name section style */

.loginMainContainer .inputConatiner {
    position: relative;
}

.loginMainContainer .inputConatiner .inputLabel {
    color: #8C8C8C;
}

.loginMainContainer .inputConatiner .input {
    border: 1px solid #DADCE0;
    border-radius: 5px;
    color: #8C8C8C;
    padding: 10px 20px;   
}


.loginMainContainer .inputConatiner .input:focus{
    outline-color: #1A73E8;
}

.loginMainContainer .inputConatiner .input:focus+.inputLabel,
.loginMainContainer .inputConatiner .input:not(:placeholder-shown)+.inputLabel {
    transform: translateY(-1.3rem);
    margin-left: 10px;
    font-size: 0.8rem;
    color: #1A73E8;
    background-color: white;
    padding: 0 0.5rem;
}

.loginMainContainer .inputConatiner .inputLabel {
    position: absolute;
    left: 5px;
    top: 24%;
    pointer-events: none;
    padding: 0 0.5rem;
    transition: transform 0.3s, font-size 0.3s, color 0.3s;
}

/* START : legal name section style */









@media (max-width: 568px) {
    .loginMainContainer {
        /* top: 25%; */
        width: 95%;
        border: 0px;
        padding: 20px 10px;
    }

    .loginMainContainer .header {
        font-size: 20px;
    }

    .loginMainContainer .otpMainContainer .otpInputContainer {
        justify-content: space-evenly;
    }

}

















