.passwordMainContainer .input {
    width: 100%;
}

.passwordMainContainer .showPasswordConatiner{
    margin-top: 10px;
    color: #8C8C8C;
}

.passwordMainContainer ul{
    margin-top: 13px;
}

.passwordMainContainer .passwordErrStatus{
    color: #8C8C8C;
    margin-top: 3px;
}

.passwordMainContainer .nextBtn{
    margin-top: 40px;
}
