.videoInnerContainer{
    display: flex;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.videoInnerContainer .videoBtnContainer{
    width: 55%;
    display: flex;
    justify-content: flex-end;
}


.videoInnerContainer .videoTitle{
    font-size: 1.12rem;
    font-weight: 500;
    margin-bottom: 0px;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.videoInnerContainer .additionalVideoIconBtn{
    padding: 8px 1.6vw;
    border-radius: 12px;
    background: #dddddd;
    border: 0px;
    font-size: 13px;
}

.videoInnerContainer .additionalVideoIconBtn:hover{
    background: #d1d0d0;
}


.videoInnerContainer .additionalVideoIconBtn i{
    padding-right: 10px;
}

.videoInnerContainer .likeBtn{
    border-radius: 12px 0px 0px 12px;
    border-right: 1px solid white;
}

.videoInnerContainer .dislikeBtn{
    border-radius: 0px 12px 12px 0px;
    width: 40px;
    padding: 8px;
}

.videoInnerContainer .dislikeBtn i{
    padding: 0px;
}

.videoInfo{
    margin-bottom: 20px;
}

.videoInfo,
.videoInfo .accordion-button{
    border-radius: 14px;
    background: #ECECEC;
}

.videoInfo .accordion-button{
    padding: 0.8rem 1.25rem 10px 1.25rem;    
}

.videoInfo .accordion-button:not(.collapsed){
    color: #000000;
    box-shadow: unset;
    background-color: transparent;
}

.videoInfo .accordion-button:focus{
    box-shadow: unset;
}

.courseDetailsReportCancleBtn{
    border: 0px;
    border-radius: 10px;
    background: rgba(192, 192, 192, 0.30);
    padding: 3px 20px;
    font-size: 14px;
    margin-right: 11px;
    cursor: pointer;
}

.courseDetailsReportNextBtn{
    border: 0px;
    border-radius: 10px;
    background: transparent;
    padding: 3px 20px;
    font-size: 14px;
}

.courseDetailsReportNextBtnAddOn{
    cursor: pointer;
    color: white;
    background-color: #252525;
}
/* .courseDetailsReportNextBtn:hover{
    border: 1px solid lightgray;
} */


#reportToast{
    opacity: 0;
    position: fixed;
    bottom: 40px;
    width: fit-content;
    right: 30px;
    background-color: #ff0d07;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    transition: opacity 0.3s ease;
}

@media (max-width: 590px) {
    .videoInnerContainer{
        display: block;
    }
    .videoInnerContainer .videoBtnContainer{
        width: 100%;
        margin-top: 10px;
        justify-content: flex-start;
    }
    .videoInnerContainer .videoTitle{
        width: 100%;
    }
    .videoInnerContainer .additionalVideoIconBtn{
        padding: 8px 3vw;
    }    
}