.userDashboardMainContainer .navContainer .navIcon {
    margin-right: 5px;
    width: 23px;
}

.userDashboardMainContainer .navContainer .nav-link {
    color: #8C8C8C;
    padding: 0.5rem 0.8rem;
}

.userDashboardMainContainer .navContainer .nav-link.active {
    color: #092E4B;
    background: #f4f4f4;
    border-radius: 10px 10px 0px 0px;
    border:0px;
}

@media (max-width: 768px) {
    .userDashboardMainContainer .tabContainer {
        margin-left: 0px;
    }
    .userDashboardMainContainer .navContainer .nav-link{
        font-size: 15px;
    }
    .userDashboardMainContainer .navContainer .navIcon{
        width: 23px;
    }
}

@media (max-width: 576px) {
    .userDashboardMainContainer .tabContainer {
        padding: 20px 10px;
    }
}