
.verificationPopup .modal-footer,
.verificationPopup .modal-header {
    border: 0px;
}

.verificationPopup .modal-header{
    padding-bottom: 0px;
}

.verificationPopup .modal-content {
    border-radius: 10px;
    border: 0px;
}

/* START : Style for initContainer */
.verificationPopup .modal-body .initContainer {
    color: #878787;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    padding: 70px 0px;
}

.verificationPopup .modal-footer .initContainer {
    width: 100%;
}

.verificationPopup .modal-footer .initContainer button {
    width: 100%;
    border-radius: 5px;
    background: #1573FE;
    border: 0px;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.15px;
    padding: 7px;
}

.verificationPopup .modal-footer .initContainer button img {
    width: 17px;
    margin-left: 10px;
}

/* END : Style for initContainer */


/* START : Style for otpStageContainer */
.verificationPopup .modal-header button {
    background: transparent;
    border: 0px;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;    
}

.verificationPopup .modal-header button img {
    width: 8px;
    margin-right: 8px;
}

.verificationPopup .modal-body .otpStageContainer .heading{
    color: #000;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

.verificationPopup .modal-body .otpStageContainer .subHeading{
    color: #5A5A5A;
    font-size: 15px;
    font-weight: 400;
}

.verificationPopup .modal-body .otpStageContainer .otpInputContainer .otp{
    width: 40px;
    height: 52px;
    margin-right: 16px;
    font-size: 23px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #E9E9E9;
}


.verificationPopup .modal-body .otpStageContainer .resendOtpBtn{
    background: transparent;
    border: 0px;
    text-decoration: underline;
    padding: 0px;
    color: #1A73E8;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 30px;
}


.verificationPopup .modal-body .otpStageContainer .tnc,
.verificationPopup .modal-body .otpStageContainer .resendText{
    color: #8C8C8C;
    font-size: 15px;
    font-weight: 400;
}

.verificationPopup .modal-body .otpStageContainer .resendText{
    text-decoration: unset !important;
}


.verificationPopup .otpStageContainer .otpSubmitBtnContainer{
    float: right;
}

.verificationPopup .otpStageContainer .otpSubmitBtnContainer .otpSubmitBtn {
    border-radius: 5px;
    background: #1573FE;
    border: 0px;
    color: #FFF;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.15px;
    padding: 4px 10px;
}


.verificationPopup .otpStageContainer .otpError{
    margin-bottom: 5px;
    color: red;
    font-size: 13px;
}
/* END : Style for otpStageContainer */