nav {
    background: #FFFFFF;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
    padding: 15px !important;
}

#navBarInputSearchForm {
    width: 40%;
    height: 40px;
    overflow-y: visible;
}

#navBarInputSearchForm {
    margin-left: 7%;
}

@media (max-width: 1610px) {
    #navBarInputSearchForm {
        /* width: 40%; */
        width: 60%;
    }
}

@media (max-width: 1232px) {
    #navBarInputSearchForm {
        width: 50%;
    }
}

@media (max-width: 992px) {
    #navBarInputSearchForm {
        /* margin-top: 15px; */
        width: 46%;
    }
}

@media (max-width: 768px) {
    #navBarInputSearchForm {
        margin-top: 15px;
        width: 100%;
    }
}


#navBarInputSearchForm .form-control {
    padding-left: 2.375rem;

    border: 1px solid #000000;
    border-radius: 19px;
}

#navBarInputSearchForm .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.nav-item a {
    margin: 0px 10px;
    color: #252525;
}


.buttonOnNavBar,
.tabButtonOnNavBar {
    /* background: #1D3661; */
    background: #252525;
    border-radius: 5px;
    padding: 10px 20px;
    color: white !important;
    text-decoration: none;
}

.buttonOnNavBar:hover,
.tabButtonOnNavBar:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 2px 4px rgba(0, 0, 0, 0.2);
    /* background: #305087;     */
    background: #363636;
}


.bottomStickyContainer {
    position: fixed;
    bottom: 20px;
    right: 18px;
    z-index: 9999;
}

.bottomStickyContainer img {
    width: 50px;
}


nav .navbar-toggler {
    margin-right: 11px;
}



/* @media (max-width: 1350px) {
    .bottomStickyContainer {
        bottom: 140px;
    }
} */

@media (max-width: 768px) {
    .buttonOnNavBar {
        width: 100%;
        display: block;
    }
}



@media (max-width: 992px) {
    #navBarTabContainer {
        width: 73%;
        display: none;
        /* justify-content: flex-start; */
        justify-content: space-evenly;
        align-items: center;
    }

    #navBarInputSearchFormContainer {
        width: 56%;
    }

    #navBarInputSearchForm {
        margin-left: 0%;
    }

}

@media (max-width: 842px) {
    #navBarTabContainer {
        width: 70%;
    }

    #navBarInputSearchFormContainer {
        width: 45%;
    }
}


@media (max-width: 767px) {
    #navBarTabContainer {

        display: flex;
        /* display: block; */
        /* width: unset;     */
        width: 17%;
    }

    .handleCartWhenPurchaseG0 {
        margin-left: 50px;
    }

    nav .navbar-toggler {
        margin-left: 3px;
    }

    .buttonOnNavBarMargin {
        margin: 0px 0px !important;
    }

}


@media (max-width: 576px) {
    nav {
        padding: 10px !important;
    }

    /* nav{
        height: 56px;
    }

    nav .container-fluid{
        margin-top: -5px;
    } */

    nav .navbar-brand-mobile {
        width: 32px !important;
    }

    nav .cartIconContainer img {
        width: 24px;
    }

    nav .cartIconContainer span {
        font-size: 8px;
        right: -10px;
    }

    nav .navbar-toggler {
        margin-right: 0px;
    }

    nav .navbar-toggler img {
        width: 20px !important;
    }

    .mobileDashboardIcon {
        width: 25px !important;
    }
}


@media (max-width: 510px) {
    #navBarTabContainer {
        width: 25%;
    }
}


.cartIconContainer img {
    width: 30px;
}

.cartIconContainer span {
    font-size: 9px;
    bottom: 17px;
    right: -4px;
    border-radius: 8px;
    padding: 0.5em 0.6em;
    background-color: #D9D9D9;
    color: black;
}

.list-group>a {
    text-decoration: none;
}

.image-parent {
    max-width: 40px;
}

.searchImg {
    width: auto;
    height: 60px;
}

.searchResults {
    max-height: 70vh;
    overflow: scroll;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
}

.searchResults::-webkit-scrollbar {
    display: none;
}

.searchText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}