#swipeButtonBackground {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    background: #C9E4F2;
    height: 43px;
    border-radius: 5px;
}



#swipeSlider {
    transition: width 0.3s, border-radius 0.3s, height 0.3s;
    position: absolute;
    left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 43px;
    left: 4px;
    background: rgb(75, 166, 214);
    color: white;
    border-radius: 5px;
    height: 43px;
}

#swipeText{
    font-size: 0.6rem;
    margin-left: 48px;
    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Webkit browsers */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.swipeButtonDiv
{
	width: 100%;
	height: 40px;
	border: none;
    background: #FDF2DC;
    /* background: #C9E4F2; */
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.swipeButtonDivLock{
    background: #DDD;
}

.swipeButton
{
	width: 40px;
	height: 40px;
	border-radius: 5px;
	position: absolute;
	cursor: pointer;
    background: #FFBC2C;
    /* background: #4BA6D6; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    font-size: 11px;
}
.swipeButtonLock{
    background: #BFBFBF;
}


.swipeButtonDiv>span{
    font-size: 11px;
    margin-left: 60px;
}

.swipeButtonDiv .arrowLogo{
    letter-spacing: -1.5px;
    color: #845201;
}

.swipeButtonDiv .arrowLogoLock{
    color: #858585;
}