.commonexplorePageAlignmentContainerHelper {
	margin: 0rem 10vw;
	max-width: 1340px;
	padding: 2rem 100px;
	text-align: left;
}
@media (max-width: 1500px) {
	.commonexplorePageAlignmentContainerHelper {
		padding: 2rem 60px;
	}
}

@media (max-width: 1340px) {
	.commonexplorePageAlignmentContainerHelper {
		margin: 0rem;
		padding: 2rem 100px;
	}
}

@media (max-width: 992px) {
	.commonexplorePageAlignmentContainerHelper {
		padding: 2rem 15px;
	}
}

@media (max-width: 768px) {
	.commonexplorePageAlignmentContainerHelper {
		padding: 1rem 15px;
	}
	.veryTopHeading {
		margin-bottom: 20px;
	}
}
