.tNcMain{
    margin: 2rem 10vw;
    max-width: 1340px;
    text-align: left;
}


.tNcMain .pinImg{
    width: 15px;
    margin-left: 10px;
}

.tNcMain .text{
    font-size: 1rem;
}

.tNcMain ul li{
    margin-bottom: 20px;
}

.tNcMain .lastImg{
    width: 50%;
}


.tNcMain .tNcPointer img {
    width: 25px;
}

.tNcMain .tNcPointer p {
    display: inline-block;
    margin-left: 10px;
}

.tNcDescription,
.tNcDescription p{
    margin-bottom: 0px;
}

@media (max-width: 1340px) {

    .tNcMain {
        margin: 2rem;
    }
}

@media (max-width: 768px) {
    .tNcMain {
        margin: 2rem 20px;
    }
}


@media (max-width: 576px) {
    .tNcMain .lastImg{
        width: 100%;
    }
}
