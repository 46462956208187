.reviewPaymentView .courseItemContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
}

.reviewPaymentView .midContainer{
    /* max-height: 300px; */
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}


.reviewPaymentView .heading .name {
    font-weight: 600 !important;
    font-size: 17px !important;
}

.reviewPaymentView .heading .price {
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #1573FE;
}
.reviewPaymentView .courseItemContainer .name{
    width: 80%;
    margin-bottom: 5px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;  
}
.reviewPaymentView .courseItemContainer .name,
.reviewPaymentView .heading .price {
    font-size: 15px;
    font-weight: 400;
}

.reviewPaymentView .discount .price {
    color: #03A800;
}

.reviewPaymentView .couponContainer{
    position: relative;
    margin-bottom: 15px;
}
.reviewPaymentView .couponContainer input[type="text"]{
    width: 100%;
    padding: 8px 6px;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
}
.reviewPaymentView .couponContainer span{
    position: absolute;
    right: 11px;
    top: 8px;
    color: #0073E6;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}

.reviewPaymentView .actionBtn {
    border-radius: 5px;
    width: 100%;
    background: #1573fe;
    border: 0px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.15px;
    padding: 6px 10px;
}

.reviewPaymentView .actionBtn img {
    width: 16px;
    margin-left: 8px;
}


/* START : style for desktop */
@media (min-width: 768px) {
    /* .paymentGatewayView .header {
      display: none;
    } */
    .reviewPaymentView .couponContainer {
        position: fixed;
        bottom: 50px;
        width: 370px;
      }  
    .reviewPaymentView .actionBtn {
      position: fixed;
      bottom: 10px;
      width: 370px;
    }
  }
/* END : style for desktop */
  