#courseMobileTopBg {
	/* Ref https://cssgradient.io/ */
	/* background: linear-gradient(0deg, rgba(0,0,0,1) -1%, rgba(111,0,49,1) 130%);	 */
}

#courseMobileTopBg .courseThumbnail {
	margin-top: 30px;
}

.bgLinesOverlapMobile{
	position: absolute;
	width: 100%;
	height: 2218px;
}

.bgBc1OverlapMobile{
	position: absolute;
	width: 100%;
	height: 2218px;
	background-size: contain;
}


@media (max-width: 768px) {
    .mobileProductPageAlignmentContainerHelper .callView {
        display: block;
    }
	.mobileProductPageAlignmentContainerHelper {
		padding: 0rem 85px !important;
	}
	#courseMobileTopBg .courseThumbnail {
		width: 80%;
	}

	.mobileDesktopTabCardHelper {
		padding: 0rem 125px !important;
	}
}

@media (max-width: 576px) {
	.mobileProductPageAlignmentContainerHelper {
		padding: 0rem 15px !important;
	}
	.mobileDesktopTabCardHelper {
		padding: 0rem 55px !important;
	}
}

@media (max-width: 460px) {
	#courseMobileTopBg .courseThumbnail {
		width: 100%;
	}
	.mobileDesktopTabCardHelper {
		padding: 0rem 15px !important;
	}
}
