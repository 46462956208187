.exploreCourseCategoryiew .exploreContentContainer {
	margin-top: 15px;
}

.exploreCourseCategoryiew .exploreContentContainer .title {
	font-weight: 700;
	font-size: 20px;
}

.exploreCourseCategoryiew .exploreContentContainer .pointerContainer {
	margin-bottom: 8.5px;
	color: #252525;
}

.exploreCourseCategoryiew .exploreContentContainer .pointerContainer img {
	width: 20px;
	margin-right: 10px;
}

.exploreCourseCategoryiew .exploreContentContainer .courseCardDiv {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.exploreCourseCategoryiew .exploreContentContainer .courseCardDiv a{
	text-decoration: none;
	color: unset;
}

.exploreCourseCategoryiew .exploreContentContainer .courseCardDiv .div1 {
	display: flex;
	align-items: center;
	width: 100%;
}

.exploreCourseCategoryiew
	.exploreContentContainer
	.courseCardDiv
	.div1
	.thumbnail {
	width: 93px;
}

.exploreCourseCategoryiew .exploreContentContainer .courseCardDiv .div1 .name {
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: 500;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

.exploreCourseCategoryiew .exploreContentContainer .courseCardDiv .div1 .price {
	font-weight: 500;
}

.exploreCourseCategoryiew .exploreContentContainer .courseCardDiv .notifyIcon {
	width: 25px;
	cursor: pointer;
}

.exploreCourseCategoryiew
	.exploreContentContainer
	.courseCardDiv
	.notifyIcon_whiteTickBlueBg {
	cursor: unset !important;
}

.notifyIconHide {
	display: none !important;
}

.exploreCourseCategoryiew
	.exploreContentContainer
	.courseCardDiv
	.crossedPrice {
	margin-right: 10px;
	margin-left: 10px;
	color: #878787;
	font-size: 16px;
	font-weight: 400;
}

.exploreCourseCategoryiew .exploreContentContainer .courseCardDiv .offerPrice {
	color: #388e3c;
	font-size: 13px;
	font-weight: 600;
}

@media (max-width: 568px) {
	.exploreCourseCategoryiew .exploreContentContainer .pointerContainer {
		font-size: 15px;
	}

	.exploreCourseCategoryiew .exploreContentContainer .description {
		font-size: 14px;
	}
}
