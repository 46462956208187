.userDashboardMainContainer .myLearningMainContainer .cHeading {
    font-weight: 600;
    align-items: center;
    display: flex;
    color: #092E4B;
    margin-bottom: 18px;
}

.userDashboardMainContainer {
    margin: 2rem 10vw;
    max-width: 1340px;
    text-align: left;
}

.userDashboardMainContainer .navContainer {
    border-bottom: 0px;
}

.userDashboardMainContainer .tabContainer {
    /* background: lightgreen; */
    background: #f4f4f4;
    margin-left: 10px;
    padding: 23px 37px 37px 37px;
    margin-top: -3px;
}



.userDashboardMainContainer .myLearningMainContainer .topAlert,
.userDashboardMainContainer .profileMainContainer .topAlert {
    background: #FFE9E9;
    border: 1.5px solid #962020;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

#personalInfo_topAlert,
#address_topAlert,
#identityVerification_topAlert{
    display: inline-flex !important;
}

.userDashboardMainContainer .myLearningMainContainer .topAlert p,
.userDashboardMainContainer .profileMainContainer .topAlert p {
    margin-bottom: 0px;
    font-size: 0.8rem;
    padding-right: 3px;
}

.userDashboardMainContainer .myLearningMainContainer .topAlert img,
.userDashboardMainContainer .profileMainContainer .topAlert img {
    width: 20px;
    /* height: fit-content; */
    height: 16px;
}

.personalHeading h5{
    /* display: flex;
    align-items: center; */
    margin-bottom: 0px;
}

.personalHeading .topAlert{
    width: 92px;
    border: 0px !important;
    padding: 5px !important;
    justify-content: space-around !important;
    margin-right: calc(var(--bs-gutter-x) * .5);
    margin-left: calc(var(--bs-gutter-x) * .5);
}

.shakeError {
    animation: shakeError 1s ease-in-out;
}

@keyframes shakeError {
    0% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-5px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}


.userDashboardMainContainer .myLearningMainContainer .profileRedirectBtn {
    background: transparent;
    color: #2B4FAD;
    border: 0px;
    text-decoration: underline;
    padding: 0px;
}


.userDashboardMainContainer .myLearningMainContainer .purchaseContainer,
.userDashboardMainContainer .myLearningMainContainer .examContainer {
    margin-top: 5px;
}

.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer {
    background: white;
    padding: 6px 10px;
    border-radius: 0px 0px 10px 10px;
}


.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer hr {
    margin: 10px 0px;
}

/* Old code */
/* .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer .progress {
    border: 1px solid #61D07A;
    border-radius: 12px;
    height: 23px;
}

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer .progress .progress-bar {
    width: 40%;
    background-color: #20D160 !important;
    border-radius: 10px;
} */

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer .progressStatus {
    display: flex;
    margin-top: 4px;
    font-size: 0.75rem;
    justify-content: space-between;
    position: relative;
}

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer .progressStatus .progressStatus0 {
    position: absolute;
}

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer .progressStatus .progressStatus100 {
    position: absolute;
    right: 0;
}

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer .progressStatus p {
    margin-bottom: 6px;
}






.userDashboardMainContainer .myLearningMainContainer .examContainer .lockExamMainContainer {
    cursor: not-allowed;
}



.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer .lockExamContainer,
.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer .examCompletedContainer {
    font-size: 0.8rem;
    display: flex;
}

.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer .examCompletedContainer {
    align-items: center;
    background: #F1FFE7;
    padding: 6px;
    border-radius: 5px;
    margin-bottom: 8px;
}

.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer .examCompletedContainer i {
    height: fit-content;
    color: #55DC47;
    padding: 3.4px 3.4px 3.3px 3.4px;
    border-radius: 20px;
    margin-right: 6px;
    border: 1px solid black;
    -webkit-text-stroke: 0.3px #000000;
}

.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer .lockExamContainer img {
    width: 11px;
    height: fit-content;
    margin-right: 6px;
}


.userDashboardMainContainer .myLearningMainContainer .examContainer .examInnerContainer .viewResultExamBtn {
    background: #252525;
    width: 100%;
    color: white;
    border: 0px;
    border-radius: 6px;
    padding: 3px;
    margin-bottom: 8px;
    font-size: 14px;
}

.userDashboardMainContainer .myLearningMainContainer .examContainer .examImageContainer {
    position: relative;
}

.userDashboardMainContainer .myLearningMainContainer .examContainer .examImageContainer .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.75);
    border-radius: 12px;
}

.lock-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.userDashboardMainContainer .profileMainContainer .editBtnContainer{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0px;
}

.userDashboardMainContainer .profileMainContainer .editBtn,
.userDashboardMainContainer .profileMainContainer .saveBtn {
    width: fit-content;
    display: flex;
    align-items: center;
    background: #252525;
    color: white;
    border: 0px;
    border-radius: 5px;
    height: fit-content;
    padding: 4px 14px;
    font-size: 14px;
}

.userDashboardMainContainer .profileMainContainer .editBtn {
    display: flex;
}

.userDashboardMainContainer .profileMainContainer .editBtn img {
    width: 13px;
    /* margin-right: 8px; */
}

.userDashboardMainContainer .profileMainContainer .cancleBtn {
    background: transparent;
    color: #535353;
    font-size: 14px;
    padding: 4px 17px;
    border: 0px;
    margin-right: 5px;
}

.userDashboardMainContainer .profileMainContainer .cancleBtn:hover {
    border: 1px solid #bfc0c1;
    border-radius: 10px;
    color: black;
}

.userDashboardMainContainer .profileMainContainer .cancleSaveBtnContainer {
    justify-content: flex-end;
    align-items: start;
    display: none;
}

/* .userDashboardMainContainer .profileMainContainer #cancleSaveBtnContainerDesktop,
.userDashboardMainContainer .profileMainContainer #cancleSaveBtnContainerMobile {
    display: none;
} */

.userDashboardMainContainer .profileMainContainer #cancleSaveBtnContainerDesktop,
.userDashboardMainContainer .profileMainContainer #cancleSaveBtnContainerMobile {
    display: none;
}


.handleToggleDisplayFlex {
    display: flex !important;
}

.handleToggleDisplayNone {
    display: none !important;
}


.userDashboardMainContainer .profileMainContainer .inputContainer {
    border: 1px solid #BDBDBD;
    padding: 0px 24px;
    border-radius: 10px;
}

.userDashboardMainContainer .profileMainContainer .inputContainer .inputLable {
    color: #898989;
    margin: 10px 0px 10px 0px;
    font-size: 0.9rem;
}


.userDashboardMainContainer .profileMainContainer .inputContainer .emailVerifyBtn{
    background: #effffd;
    border: 0px;
    border-radius: 5px;
    font-size: 0.8rem;
    padding: 3px 10px;
    box-sizing: 1px 1px 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.userDashboardMainContainer .profileMainContainer .inputContainer .emailVerifyBtn:hover{
    background: #d9f7f3;
}



.userDashboardMainContainer .profileMainContainer .inputContainer .input {
    border: 0px;
    font-size: 0.9rem;
}

.userDashboardMainContainer .profileMainContainer .inputContainer .input:disabled{
    background: rgb(242, 242, 242);
}

.userDashboardMainContainer .profileMainContainer .inputContainer .inputHr {
    margin: 0px;
}

.userDashboardMainContainer .profileMainContainer .inputHeadingContainer {
    margin: 41px 0px 13px 0px;
}

.userDashboardMainContainer .profileMainContainer .inputHeadingContainer h5 {
    width: fit-content;
    margin-bottom: 0px;
}

.userDashboardMainContainer .profileMainContainer .inputHeadingContainer .topAlert,
.userDashboardMainContainer .profileMainContainer .inputContainer .missingAlert {
    width: 92px;
    border: 0px;
    padding: 5px;
    justify-content: space-around;
}



.userDashboardMainContainer .profileMainContainer .inputHeadingContainer .topAlert img,
.userDashboardMainContainer .profileMainContainer .inputContainer .missingAlert img {
    width: 17px;
}

.userDashboardMainContainer .profileMainContainer .infoAlterContainer {
    background: #E8F9FF;
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    border-radius: 5px;
}

.userDashboardMainContainer .profileMainContainer .infoAlterContainer p {
    margin-bottom: 0px;
    font-size: 0.8rem;
    padding-right: 3px;
}

.userDashboardMainContainer .profileMainContainer .infoAlterContainer img {
    width: 20px;
    /* height: fit-content; */
    height: 17px;
}

.userDashboardMainContainer .profileMainContainer .inputContainer .verifiedAlertContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.userDashboardMainContainer .profileMainContainer .verifiedAlert {
    width: 92px;
    border: 0px;
    padding: 5px;
    justify-content: space-around;
    background: #EFFFFD;
}

.userDashboardMainContainer .profileMainContainer .verifiedAlert img {
    width: 17px;
}

.userDashboardMainContainer .profileMainContainer .uploadIcon {
    width: 20px;
    cursor: pointer;
    /* height: fit-content; */
    height: 19px;
    margin-left: 10px;
}

.userDashboardMainContainer .profileMainContainer .inputContainer .dropdown-drop-focus-effect:focus {
    box-shadow: none;
}

.userDashboardMainContainer .profileMainContainer .inputContainer .dropdown-drop-disable-effect{
    background-color: #fff !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 16px 12px !important;
}


#dobError{
    display: none;
    font-size: 10px;
    color: #6e0101;
}
#emailVerifyToast{
    opacity: 0;
    position: fixed;
    bottom: 40px;
    width: fit-content;
    right: 30px;
    background-color: #a9f0aa;
    color: #000000;
    padding: 7px;
    border-radius: 5px;
    transition: opacity 0.3s ease;

    /* position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%); */
}





    

.uploadedProofFileName{
    white-space: nowrap;
    width: 139px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    margin-bottom: 0px;
    text-align: right;
    padding: 0px 8px;

}



@media (max-width: 1340px) {
    .userDashboardMainContainer {
        margin: 2rem;
    }
}

@media (max-width: 768px) {
    .userDashboardMainContainer {
        margin: 2rem 0px;
    }
}



@media (max-width: 768px) {
    .userDashboardMainContainer .profileMainContainer .personalHeading {
        padding-right: 0px !important;
    }

    /* .userDashboardMainContainer .profileMainContainer #cancleSaveBtnContainerDesktop,
    .userDashboardMainContainer .profileMainContainer #editBtnDesktop {
        display: none !important;
    } */

}

@media (max-width: 576px) {
    .userDashboardMainContainer {
        margin: 0rem 0px;
    }

    .userDashboardMainContainer .tabContainer {
        margin-left: 0px;
        padding: 20px;
    }
}


@media (min-width: 576px) {
    .userDashboardMainContainer .profileMainContainer .inputContainer .col-imp-sm-8 {
        flex: 0 0 auto !important;
        width: 66.66666667% !important;
    }

    .userDashboardMainContainer .profileMainContainer .inputContainer .col-my-sm-3 {
        flex: 0 0 auto !important;
        width: 25%;
    }
}

@media (min-width: 768px) {
    .userDashboardMainContainer .profileMainContainer .inputContainer .col-imp-md-9 {
        flex: 0 0 auto !important;
        width: 75% !important;
    }

    .userDashboardMainContainer .profileMainContainer .inputContainer .col-my-md-2 {
        flex: 0 0 auto !important;
        width: 16.66666667%;
    }
}

@media (min-width: 992px) {
    .userDashboardMainContainer .profileMainContainer .inputContainer .col-imp-lg-10 {
        flex: 0 0 auto !important;
        width: 83.33333333% !important;
    }

    .userDashboardMainContainer .profileMainContainer .inputContainer .col-my-lg-1 {
        flex: 0 0 auto !important;
        width: 10% !important;
    }

    .userDashboardMainContainer .profileMainContainer .inputContainer .col-contact-number-lg-9 {
        flex: 0 0 auto !important;
        width: 73.3% !important;
    }
}


.handleMargin_P_editBtn{
    margin-top: 13px;
    margin-bottom: 0px;
}

@media (max-width: 576px) {
    .userDashboardMainContainer .profileMainContainer .inputContainer .col-imp-7 {
        flex: 0 0 auto !important;
        width: 58.33333333% !important;
    }

    .userDashboardMainContainer .profileMainContainer .inputContainer .col-my-3 {
        flex: 0 0 auto !important;
        width: 25%;
    }

    /* .userDashboardMainContainer .profileMainContainer .editBtnContainer{
        margin-bottom: 20px;
    } */
        
    .userDashboardMainContainer .profileMainContainer .editBtn img{
        width: 18px;
        margin: 3px 0px;
    }

}

.viewResultExamBtn {
    padding-top: 5px;
    padding-bottom: 5px;
}