.commentTopSection {
    align-items: center;
}

.commentTopSection p {
    margin-bottom: 0px;
    width: fit-content;
}

.commentTopSection button {
    padding: 3px;
    text-align: left;
    font-size: 14px;
    width: 65px;
    background: transparent;
    border: 0px;
}

.commentTopSection button:hover {
    color: #5b5555;
}



.userCommentContainer .col-userCommentInput {
    width: 94%;
}

.userCommentContainer input {
    font-size: 14px;
    border-width: 0px 0px 1px 0px;
}

.userCommentContainer input:focus {
    outline-style: solid;
    outline-width: 0.11px;
    padding: 6px;
    border-width: 0px 0px 0px 0px;
    border-radius: 2px;
}

.userCommentContainer .CCBtnContainer {
    width: 100%;
    justify-content: flex-end;
}

.userCommentContainer .cancleBtn {
    width: 100px;
    margin-right: 15px;
    background: transparent;
    border: 0.5px solid #bfbcbc;
    border-radius: 30px;
    padding: 5px 0px;
    font-size: 0.8rem;
}

.userCommentContainer .cancleBtn:hover {
    background: #ECECEC
}


.userCommentContainer .continueBtn {
    width: 100px;
    margin-right: 15px;
    border: 0px;
    border-radius: 30px;
    background: #ECECEC;
    color: #909090;
    padding: 5px 0px;
    font-size: 0.8rem;
}

.col-profileImgPlaceholder {
    margin-right: 3px;
    width: 4%;
    padding-left: 0px;
}

.continueBtnAddOn {
    background: #085ed4 !important;
    color: white !important;
}

.userCommentContainer .continueBtnAddOn:hover {
    background: #083f8b !important;
    color: white;
}


@media (max-width: 470px) {
    .col-profileImgPlaceholder {
        width: 7%;
    }

    .userCommentContainer .col-userCommentInput {
        width: 92%;
    }

}