.requestACallMainContainer {
    margin: 0px 10vw;
    max-width: 1340px;
    text-align: left;
}

.requestACallInnerContainer {
    margin: 100px 0px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
}


.requestACallMainContainer .peopleContainer,
.requestACallMainContainer .formContainer{
    padding: 19px 17px;
}

.requestACallMainContainer .formContainer{
}

.formContainer_thankyouFormContainer{
    display: flex;
    align-items: center;
}

.requestACallMainContainer .peopleContainer .peopleImg{
    width: 100%;
}

.requestACallMainContainer .peopleContainer ul{
    margin: 30px 0px;
}

.requestACallMainContainer .peopleContainer li::marker {
    color: #D9D9D9;
}

.requestACallMainContainer .peopleContainer .note{
    padding-left: 1rem;
    color: #B1B1B1;
    font-size: 14px;
}


.requestACallMainContainer .formContainer input[type="text"],
.requestACallMainContainer .formContainer input[type="email"],
.requestACallMainContainer .formContainer input[type="tel"],
.requestACallMainContainer .formContainer select,
.requestACallMainContainer .formContainer textarea{
    border-radius: 5px;
    border: 0.5px solid #000;
    padding: 8px 8px;
    margin-bottom: 17px;
}

.requestACallMainContainer .formContainer input[type="tel"]:read-only{
    background-color: #e9ecef;
}

.requestACallMainContainer  .submitBtnContainer{
    float: right;
}

.requestACallMainContainer  .submitBtnContainer button{
    border-radius: 5px;
    background: #252525;
    border: 0px;
    color: white;
    padding: 2px 40px;
}

.requestACallMainContainer .thankYouContainer{
    text-align: center;
    margin: 0px 30px;
}

.requestACallMainContainer .thankYouContainer img{
    width: 30%;
    margin-bottom: 70px;
}

.requestACallMainContainer .thankYouContainer p{
    color: #B1B1B1;
}

@media (max-width: 1340px) {
    .requestACallMainContainer {
        margin: 2rem;
    }
}


@media (max-width: 992px) { 
    .requestACallMainContainer .thankYouContainer img{
        width: 50%;
    }    
}

@media (max-width: 768px) {
    .requestACallMainContainer {
        margin: 3rem 20px;
    }

    .requestACallInnerContainer{
        margin-top: 58px;
    }

    .requestACallMainContainer .thankYouContainer img{
        width: 30%;
    }    

}

@media (max-width: 568px) {
    .requestACallInnerContainer{
        margin-top: 0px;
    }
    .requestACallMainContainer .thankYouContainer img{
        width: 50%;
        margin-bottom: 30px;
    }    
}