/* Can be used to ristic the height of modal */
/* .restOfPopupsOfCoursePageView .modal-content {
  height: 550px;
  overflow: scroll;
} */

.selectBlueBorder {
	border-width: 2px !important;
	border-color: #1573fe !important;
}

.restOfPopupsOfCoursePageView .cartBackground {
	background: black;
	position: fixed;
	top: 0px;
	z-index: 9999;
	width: 100%;
	height: 100%;
	opacity: 0.4;
	display: none;
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */
/* START : Desktop style */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */
.restOfPopupsOfCoursePageView .mainDesktopCartConatiner .fixedCartNote {
	background-color: white;
	z-index: 9;
	position: fixed;
	/* top: 150px; */
	padding: 7px 20px;
	display: flex;
	justify-content: space-between;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 5px;
	border: 1px solid #d4d3d3;
	box-shadow: 0px 4px 26px 0px #8ba7ad40;
}

.restOfPopupsOfCoursePageView
	.mainDesktopCartConatiner
	.fixedCartNote
	.viewCartText {
	text-decoration: underline;
	color: #1573fe;
	font-weight: 500;
	cursor: pointer;
}

.restOfPopupsOfCoursePageView .mainDesktopCartConatiner .cartContainerDesktop {
	padding: 40px 16px 5px 16px;
	background: white;
	width: 0px;
	height: 100%;
	position: fixed;
	top: 0px;
	z-index: 9999;
	right: 0px;
	display: none;
}

.restOfPopupsOfCoursePageView
	.mainDesktopCartConatiner
	.cartContainerDesktop
	.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}
.restOfPopupsOfCoursePageView
	.mainDesktopCartConatiner
	.cartContainerDesktop
	.headerContainer
	.heading {
	font-size: 22px;
	font-weight: 400;
}
.restOfPopupsOfCoursePageView
	.mainDesktopCartConatiner
	.cartContainerDesktop
	.headerContainer
	.blackCross {
	width: 15px;
	cursor: pointer;
}
.restOfPopupsOfCoursePageView
	.mainDesktopCartConatiner
	.cartContainerDesktop
	.subHeadingContainer
	.subHeading {
	font-size: 12px;
	font-weight: 400;
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */
/* START : Desktop style */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */
/* START : Mobile style */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */
.stickyCartCotainer {
	display: none;
	background-color: white;
	border-radius: 15px 15px 0px 0px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 0px 12px 22px 12px;
}

.stickyCartCotainer .topGrayArrowContainer {
	justify-content: center;
	display: flex;
	margin-bottom: 12px;
	/* background-color: red; */
}

.stickyCartCotainer .topGrayArrowContainer img {
	width: 37px;
	height: 8px;
	margin-top: 15px;
}


.stickyCartCotainer .priceContainer{
	display: flex;
	align-items: center;
	margin-left: 11px;
}
.stickyCartCotainer .priceContainer p{
	margin-right: 10px;
	margin-bottom: 5px;
}
.stickyCartCotainer .priceContainer .alwaysDisplayPrice{
	font-size: 17px;
	font-weight: 500;
}
.stickyCartCotainer .priceContainer .crossPrice{
	font-size: 15px;
	font-weight: 400;
	color: #878787;
}
.stickyCartCotainer .priceContainer .discountedPercentage{
	font-size: 13px;
	font-weight: 600;
	color: #388E3C;

}



.stickyCartCotainer .dealEndingContainer {
	color: #ff0000;
	margin-bottom: 6px;
	margin-left: 11px;
    display: flex;
    align-items: center;
}
.stickyCartCotainer .dealEndingContainer img {
	width: 14px;
	margin-right: 8px;
}

.stickyCartCotainer .stickyCartInternalView {
	/* display: none; */
}

/* START : style for registration */
.stickyCartCotainer .stickyCartInternalView .registrationMainContainer {
	border: 0px;
	padding: 0px 11px 11px 11px;
}

.stickyCartCotainer
	.stickyCartInternalView
	.registrationMainContainer
	.css-1fdsijx-ValueContainer {
	padding: 2px 2px;
}

.stickyCartCotainer
	.stickyCartInternalView
	.registrationMainContainer
	.css-1xc3v61-indicatorContainer {
	padding: 4px;
}

/* END : style for registration */

/* START : style for OTP */
.stickyCartCotainer .stickyCartInternalView .otpStageContainer .verifyNumText {
	font-weight: 400;
	font-size: 13px;
	color: #878787;
}

.stickyCartCotainer
	.stickyCartInternalView
	.otpStageContainer
	.otpInputContainer {
	display: flex;
	justify-content: space-evenly;
}

.stickyCartCotainer
	.stickyCartInternalView
	.otpStageContainer
	.otpInputContainer
	.otp {
	width: 40px;
	height: 52px;
	margin-right: 16px;
	font-size: 23px;
	margin-bottom: 15px;
	border-radius: 5px;
	border: 1px solid #e9e9e9;
}

.stickyCartCotainer .stickyCartInternalView .otpStageContainer .resendOtpBtn {
	color: #1a73e8;
	text-decoration: underline;
	border: 0px;
	background-color: transparent;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 8px;
}

.stickyCartCotainer .stickyCartInternalView .otpStageContainer .tnc {
	display: none;
}

.stickyCartCotainer .stickyCartInternalView .goBackContainer {
	display: flex;
	align-items: center;
}

.stickyCartCotainer .stickyCartInternalView .goBackContainer img {
	width: 8px;
	margin-right: 10px;
}

/* END : style for OTP */

.stickyCartCotainer .btnContainer {
	display: flex;
	justify-content: space-around;
}

.stickyCartCotainer .btnContainer .col-my5 {
	width: 45%;
}

.stickyCartCotainer .btnContainer .enrollNowBtn,
.stickyCartCotainer .btnContainer .contactUsBtn {
	background-color: #ffd814;
	border-radius: 5px;
	color: #1573fe;
	font-size: 16px;
	font-weight: 700;
	border: 0px;
	padding: 6px 0px;
}

.stickyCartCotainer .btnContainer .enrollNowBtn a{
	text-decoration: none;
}

.stickyCartCotainer .btnContainer .cartActionBtn {
	background-color: white;
	border: 0.5px solid #d1d1d1;
	font-size: 16px;
	font-weight: 700;
	padding: 6px 0px;
	border-radius: 5px;
	box-shadow: 0px 4px 4px 0px #0000000f;
}

.stickyCartCotainer .actionBtnContainerOfToLevel .cartItemText {
	text-align: center;
}

.stickyCartCotainer .actionBtnContainerOfToLevel .actionBtn {
	border-radius: 5px;
	width: 100%;
	background: #1573fe;
	border: 0px;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: -0.15px;
	padding: 6px 10px;
}

.stickyCartCotainer .actionBtnContainerOfToLevel .actionBtn img {
	width: 16px;
	margin-left: 8px;
}

@media (max-width: 768px) {
	.stickyCartCotainer {
		display: block;
	}

	.restOfPopupsOfCoursePageView .mainDesktopCartConatiner {
		display: none;
	}
}

@media (max-width: 568px) {
	.stickyCartCotainer
		.stickyCartInternalView
		.otpStageContainer
		.otpInputContainer
		.otp {
		margin-right: 0px;
		width: 36px;
		height: 43px;
		font-size: 20px;
	}
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */
/* END : Mobile style */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++= */
