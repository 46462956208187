.mobile_userDashboardMainContainer .mobileNavContainer {
    border-radius: 20px 20px 0px 0px;
    border: 1px solid #D1D1D1;
    background: #F4F4F4;
}

.mobile_userDashboardMainContainer .navContainer .nav-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0px;
    padding: 0rem 0rem;
    margin: 12px 8px;
}

.mobile_userDashboardMainContainer .navContainer .navTitle{
    font-size: 14px;
}


@media (max-width: 576px) {
    .userDashboardMainContainer .navContainer .nav-link.active{
        background-color: unset;
    }
}

@media (max-width: 410px) {
    .userDashboardMainContainer .navContainer .navIcon{
        width: 20px;
    }
    .mobile_userDashboardMainContainer .navContainer .navTitle{
        font-size: 11px;
    }    
}