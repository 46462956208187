.roadMapView {
    text-align: center;
}

.roadMapView .heading {
    font-weight: 600;
    font-size: 23px;
    margin-bottom: 30px;
}

.row-my {
    display: flex;
    justify-content: center;
}

.roadMapView .col-my-4 {
    width: 278px;
    /* background-color: lightblue; */
}

.roadMapView .col-my-2 {
    width: 35px;
    /* background-color: lightgray; */

    display: flex;
    flex-direction: column;
    align-items: center;    
}

.roadMapView .lhs {
    text-align: right;
    margin-right: 100px;
}

.roadMapView .rhs {
    text-align: left;
    margin-left: 100px;
}


.roadMapView .row-my .actionImg {
    width: 194px;
}

.roadMapView .row-my .heading {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 12px;
}

.roadMapView .row-my .content {
    font-weight: 400;
}

.roadMapView .row-my .numImg {
    width: 100%;
}

.roadMapView .row-my .numLineImg {
    height: 270px;
}



@media (max-width: 992px) {
    .roadMapView .lhs {
        margin-right: 80px;
    }
    
    .roadMapView .rhs {
        margin-left: 80px;
    }    
}


@media (max-width: 768px) {
    .roadMapView .heading {
        font-size: 21px;
    }
    .roadMapView .row-my .actionImg {
        width: 170px;
    }
    .roadMapView .row-my .heading {
        font-size: 20px;
        margin-bottom: 7px;
    }

    .roadMapView .col-my-2 {
        width: 33px;
    }

    .roadMapView .lhs {
        margin-right: 40px;
    }
    
    .roadMapView .rhs {
        margin-left: 40px;
    }    
}

@media (max-width: 576px) {
    .roadMapView .heading {
        font-size: 19px;
    }

    .roadMapView .row-my .actionImg {
        width: 150px;
    }

    .roadMapView .row-my .heading {
        font-size: 18px;
    }

    .roadMapView .row-my .content {
        font-size: 15px;
    }

    .roadMapView .col-my-2 {
        width: 42px;
    }
    
    .roadMapView .lhs {
        margin-right: 20px;
    }
    
    .roadMapView .rhs {
        margin-left: 20px;
    }    

}


@media (max-width: 430px) {
    .roadMapView .row-my .actionImg {
        width: 100%;
    }
    .roadMapView .col-my-2 {
        width: 55px;
    }
}
