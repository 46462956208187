.exploreDesktopTabView .exploreContentContainer {
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 40px;
}
.exploreDesktopTabView .exploreContentContainer .textContainer .title {
  font-weight: 700;
  font-size: 20px;
}

.exploreDesktopTabView
  .exploreContentContainer
  .textContainer
  .pointerContainer {
  margin-bottom: 8.5px;
}
.exploreDesktopTabView
  .exploreContentContainer
  .textContainer
  .pointerContainer
  img {
  width: 20px;
  margin-right: 13px;
}

.exploreDesktopTabView .exploreContentContainer .videoContainer {
  position: relative;
  padding-bottom: 56.25%;
}

.exploreDesktopTabView .exploreContentContainer .videoContainer iframe {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.exploreDesktopTabView .relatedCourses {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 10px;
}

.exploreDesktopTabView .relatedCoursesMainContainer {
  position: relative;
}

.exploreDesktopTabView .relatedCourses .leftCarouselArrow,
.exploreDesktopTabView .relatedCourses .rightCarouselArrow {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 99;
  top: 21%;
  cursor: pointer;
}
.exploreDesktopTabView .relatedCourses .leftCarouselArrow {
  left: -20px;
}
.exploreDesktopTabView .relatedCourses .rightCarouselArrow {
  right: -20px;
}
.exploreDesktopTabView .relatedCourses .courseContainer {
  margin-right: 30px;
  position: relative;
}
.exploreDesktopTabView .relatedCourses .courseContainer .courseThumbnail {
  cursor: pointer;
  position: relative;
}
.exploreDesktopTabView .relatedCourses .courseContainer .notifyIcon {
  width: 30px;
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0.87;
  cursor: pointer;
}
.exploreDesktopTabView .relatedCourses .courseContainer .notifyIcon_whiteTickBlueBg{
  width: 28px;
  cursor: unset !important;
}

.notifyIconHide{
  display: none !important;
}

.exploreDesktopTabView .relatedCourses .courseContainer .title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 10px;
  overflow: hidden;
}
.exploreDesktopTabView
  .relatedCourses
  .courseContainer
  .priceContainer
  .priceAlwaysDisplyed {
  font-weight: 500;
}
.exploreDesktopTabView
  .relatedCourses
  .courseContainer
  .priceContainer
  .crossedPrice {
  margin-right: 10px;
  margin-left: 10px;
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}
.exploreDesktopTabView
  .relatedCourses
  .courseContainer
  .priceContainer
  .offerPrice {
  color: #388e3c;
  font-size: 13px;
  font-weight: 600;
}
