.faqView .heading {
	font-weight: 600;
	margin-bottom: 20px;
	font-size: 23px;
}

.faqView .faqAccordionContainer {
	margin-bottom: 18px;
}

.faqView .faqAccordionContainer .accordion-button {
	font-size: 1.1rem;
	font-weight: 500;
	border: 1px solid #e4e4e4;
	/* border: 1px solid black;   */
	border-radius: 5px;
	box-shadow: none !important;
}

.faqView .faqAccordionContainer .accordion-button p {
	margin-bottom: 0px;
	max-width: 95%;
}

.faqView .faqAccordionContainer .accordion-button:not(.collapsed) {
	background-color: transparent;
	color: black;
	border-width: 1px 1px 0px 1px;
	border-radius: 5px 5px 0px 0px;
}

.faqView .faqAccordionContainer .accordion-button::after {
	position: absolute;
	right: 11px;
}

.faqView .faqAccordionContainer .accordion-button:not(.collapsed)::after {
	content: '−';
	margin-top: -21px;
	font-size: 1.6rem;
	color: #888888;
	font-weight: 700;
	background-image: url('');
	transform: none;
}

.faqView .faqAccordionContainer .accordion-button.collapsed::after {
	content: '+';
	margin-top: -21px;
	font-size: 1.6rem;
	color: #888888;
	font-weight: 700;
	background-image: url('');
	transform: none;
	box-shadow: none;
}

.faqView .faqAccordionContainer .customAccordionCollapse {
	padding: 0px 20px;
	border-color: #e4e4e4;
	/* border-color: black; */
	border-style: solid;
	border-width: 0px 1px 1px 1px;
	border-radius: 0px 0px 5px 5px;
}

@media (max-width: 768px) {
	.faqView .heading {
		font-size: 21px;
	}
}

@media (max-width: 576px) {
	.faqView .heading {
		font-size: 19px;
	}
}
