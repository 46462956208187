.dobMainContainer .inputConatiner .input{
    width: 100%;
    padding: 10px 18px;
}

.dobMainContainer .inputConatiner .inputLabel{
    left: 18px;
}

.dobMainContainer #monthLabel,
.dobMainContainer #genderLabel{
    display: none;
}

.dobMainContainer select{
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
}

.dobMainContainer .nextBtn{
    margin-top: 70px;
}
