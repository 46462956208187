.relatedCoursesViewMainContainer .heading {
	font-weight: 600;
	margin-bottom: 20px;
	font-size: 23px;
}

.relatedCoursesViewMainContainer .realtedProducts {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.relatedCoursesViewMainContainer .realtedProducts div {
	margin-right: 20px;
}

.relatedCoursesViewMainContainer .realtedProducts img {
	margin-bottom: 17px;
}

.relatedCoursesViewMainContainer .realtedProducts .courseTitle {
	font-weight: 500;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

@media (max-width: 768px) {
	.relatedCoursesViewMainContainer .heading {
		font-size: 21px;
	}
}

@media (max-width: 576px) {
	.relatedCoursesViewMainContainer .heading {
		font-size: 19px;
	}

	.relatedCoursesViewMainContainer .realtedProducts .courseTitle {
		font-size: 15px;
	}
}
