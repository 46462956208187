.homePageMainBannerContainer {
    margin: 0px 10vw;
    max-width: 1340px;
    text-align: left;
}

.homePageMainContainer {
    margin: 2rem 10vw;
    max-width: 1340px;
    text-align: left;
}

#banner {
    /* margin: 0px;
    max-width: 100%;     */
}
#banner img {
    width: 100%;
}

#bannerTextDivDektop,
#bannerTextDivTablet {
    left: 5% !important;
    top: 20% !important;
    max-width: 39%;
}

#bannerTextDivDektop h3,
#bannerTextDivTablet h3 {
    /* background: linear-gradient(93deg, #1D3661 0%, #2F67CB 20%); */
    color: #1D3661;
    background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    font-weight: 700;
    margin-bottom: 3px;
}

#bannerTextDivDektop .gradientHeading{
    background: linear-gradient(93deg, #644BFF, #F40143);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

#bannerTextDivDektop div,
#bannerTextDivTablet div {
    color: white;
    background: linear-gradient(90deg, #1D3661 0%, #2F68CB 99.15%);
    border-radius: 5px;
    width: 80%;
    padding: 11px 2rem 11px 11px;
    margin-top: 5%;
}

@media (max-width: 1150px) {
    #bannerTextDivDektop div {
        width: 80%;
        padding: 11px 11px 11px 11px;
    }

    #bannerTextDivDektop h3 {
        font-size: 1.5rem;
    }
}

#bannerTextDivDektop div p,
#bannerTextDivTablet div p {
    margin-bottom: 0px;
    font-size: 1rem;
}

@media (max-width: 992px) {
    #banner img {
        /* content: url('../../assets/homePage/tabBanner.png') */
        content: url('../../assets/homePage/tabBanner.svg')
    }

    #bannerTextDivTablet {
        max-width: 50%;
    }

    #bannerTextDivTablet div {
        width: 70%;
        padding: 8px 8px;
    }

    #bannerTextDivTablet h3 {
        color: #1D3661;
        -webkit-text-fill-color: unset;
        font-size: 1.5rem;
        margin-bottom: 0px;
    }

    /* #bannerTextDivTablet div {
        background: #1D3661;
    } */

    #bannerTextDivTablet #gradientHeading {
        background: linear-gradient(93deg, #644BFF, #F40143);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media (max-width: 768px) {

    #bannerTextDivTablet h3,
    #bannerTextDivTablet #gradientHeading {
        font-size: 1.2rem;
    }
}

@media (max-width: 576px) {
    #banner img {
        content: url('../../assets/homePage/mobileBanner.png')
    }

    #bannerTextDivTablet {
        max-width: 100%;
        width: 100%;
        left: unset !important;
        padding: 20px;
        top: 8% !important;
    }

    #bannerTextDivTablet h3,
    #bannerTextDivTablet #gradientHeading {
        font-size: 1.8rem;
    }


    #bannerTextDivTablet div {
        width: 100%;
    }

    #bannerTextDivTablet div p{
        font-size: 1.2rem;
    }
}

@media (max-width: 400px) {
    #bannerTextDivTablet div p{
        font-size: 1rem;
    }
}

#courseSection .heading {
    /* color: #1D3661; */
    font-weight: 700;
}

#courseSection .subHeading {
    color: #252525;
    font-weight: 400;
}

@media (min-width: 768px) {
    #courseSection .heading {
        font-size: 2.1rem;
    }

    #courseSection .subHeading {
        font-size: 1.1rem;
    }
}

@media (min-width: 1200px) {
    #courseSection .heading {
        font-size: 3rem;
    }

    #courseSection .subHeading {
        font-size: 1.5rem;
    }
}

#courseList {
    box-sizing: border-box;
    /* border: 1px solid #252525; */
    border: 1px solid #dddddd;
    border-radius: 20px;
    padding: 30px 20px;
}

#courseList .heading {
    color: #252525;
    font-weight: 600;
}

#courseList .subHeading {
    color: #252525;
    font-weight: 400;
}

@media (min-width: 1200px) {
    #courseList .heading {
        font-size: 1.75rem;
    }

    #courseList .subHeading {
        font-size: 1.5rem;
    }
}

@media (min-width: 768px) {
    #courseList .heading {
        font-size: 1.5rem;
    }

    #courseList .subHeading {
        font-size: 1.15rem;
    }
}

.homePageBtn {
    width: 200px;
    height: 43px;
    /* background: #1D3661; */
    background: #252525;
    border-radius: 7px;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homePageBtn:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 2px 4px rgba(0, 0, 0, 0.2);
    background: #363636;
    color: white;
}

#courseList .courseDescription,
#courseSection2 .courseDescription {
    font-size: 0.93rem;
    font-weight: 500;
    margin-top: 18px;
}

#courseList .courseDescription,
#courseSection2 .courseDescription {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 5px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; */
}

#courseList .coursePrice,
#courseSection2 .coursePrice {
    font-size: 1.1rem;
    font-weight: 700;
}


.courseListChild,
.courseSection2InnerSection3 {
    cursor: pointer;
}


#anatomySection {
    /* background-color: #F7F9F8; */
    /* background-color: red; */
    padding: 30px 30px 0px 30px;
    color: #1D3764;
}


@media (max-width: 576px) {
    #anatomySection {
        padding: 30px 15px 0px 15px;
    }
}

#anatomySection h1 {
    font-weight: 700;
}

#anatomySection p {
    font-weight: 400;
}

@media (min-width: 992px) {
    #anatomySection h1 {
        font-size: 3rem;
    }

    #anatomySection p {
        font-size: 1.3rem;
        line-height: 29.05px;
    }
}


@media (min-width: 280px) {
    #anatomySection h1 {
        font-size: 2.3rem;
    }

    #anatomySection p {
        font-size: 0.8rem;
        line-height: 18.78px;
    }
}

@media (min-width: 576px) {
    #anatomySection h1 {
        font-size: 1.5rem;
    }

    #anatomySection p {
        font-size: 0.9rem;
        line-height: 18.78px;
    }
}

@media (min-width: 768px) {
    #anatomySection h1 {
        font-size: 2.1rem;
    }

    #anatomySection p {
        font-size: 1.1rem;
        line-height: 21.78px;
    }
}

@media (min-width: 992px) {
    #anatomySection h1 {
        font-size: 3rem;
    }

    #anatomySection p {
        font-size: 1.3rem;
        line-height: 29.05px;
    }
}

@media (min-width: 1200px) {
    #anatomySection h1 {
        font-size: 3.2rem;
    }
}


#anatomySection .textDiv {
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: baseline;
    align-content: center;
    justify-content: center;
}


#anatomySection .offerImgDiv {
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
}



.homePageUnDomainOfferPage2 {
    margin: unset !important;
    max-width: unset !important;
    text-align: unset !important;
}

#offersDesktopView{
    margin: 0px;
    max-width: 100%;
}

#offersDesktopView_internal_div{
    display: flex;
    max-width: 1340px;
}

#offersDesktopView,
#offersTMView {
    /* background-image: url("../../assets/homePage/offerSectionBgV2.png"); */
    background-image: url("../../assets/homePage/offerSectionBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
}

/* START : Offer card new UI */
#offersDesktopView .offerContainerHomePage,
#offersTMView .offerContainerHomePage{
    background: white;
    border-radius: 24px;
    margin-bottom: 20px;
    margin-top: 20px;
}
#offersDesktopView .offerContainerHomePage{
    margin-left: 40px;
}

#offersDesktopView .offerContainerHomePage .offerContainer1,
#offersTMView .offerContainerHomePage .offerContainer1  {
    margin-top: -20px;
    margin-right: -30px;
    margin-bottom: -20px;
}

#offersDesktopView .offerContainerHomePage .offerDiscountPercentage,
#offersTMView .offerContainerHomePage .offerDiscountPercentage{
    color: #EC2D2D;
}

#offersDesktopView .offerContainerHomePage .offerDescription,
#offersTMView .offerContainerHomePage .offerDescription{
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
/* END :  Offer card new UI */

@media (max-width: 576px) {
    #offersTMView {
        padding: 30px 15px;
    }

    #offersTMView .textDiv {
        align-items: center !important;
    }

    #offersTMView .offerContainerHomePage{
        margin-bottom: -10px;
    }
}


#offersDesktopView .textDiv,
#offersTMView .textDiv {
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: baseline;
    align-content: center;
    justify-content: center;
    color: white;
}

#offersDesktopView .textDiv h1,
#offersTMView .textDiv h1 {
    font-weight: 700;
}

#offersTMView .textDiv h1 {
    font-size: 2.7rem;
}

#offersDesktopView .textDiv p {
    font-weight: 400;
    line-height: 24px;
}


#offersTMView .textDiv p {
    font-weight: 400;
    line-height: 18px;
}


@media (min-width: 768px) {
    #offersDesktopView .textDiv h1 {
        font-size: 2.6rem;
    }

    #offersDesktopView .textDiv p {
        font-size: 1.1rem;
    }
}

@media (min-width: 992px) {
    #offersDesktopView .textDiv p {
        font-size: 1.1rem;
        line-height: 20px;
    }
}

@media (min-width: 1200px) {
    #offersDesktopView .textDiv h1 {
        font-size: 3.2rem;
    }
}


#offersDesktopView a,
#offersTMView a {
    text-decoration: none;
    color: #1D3661;
}


/* START : Shine Effect 
Ref : https://codepen.io/Ondreas/pen/VwjXdZY */
@keyframes shine {

    20%,
    100% {
        transform: translateX(150%);
    }
}

.exploreOfferesBtn {
    position: relative;
    margin-top: 3%;
    height: 42px;
    background-color: transparent;
    width: 220px;
    background-image: url('@assets/homePage/exploreOffers.png');
    background-size: cover;
    border: 0;
    border-radius: 10px;
    overflow: hidden;

}

.exploreOfferesBtn .shine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(to right,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, .5) 50%,
            rgba(255, 255, 255, 0) 75%);
    transform: skewX(-20deg) translateX(-100%);
    animation: 3s linear 0s infinite forwards shine;
}

/* END : Shine effect */

@media (max-width: 992px) {
    .exploreOfferesBtn {
        height: 40px;
        width: 200px;
    }
}

@media (max-width: 568px) {
    .exploreOfferesBtn {
        width: 250px;
        height: 52px;
        margin-top: 50px;
    }
}



#offersDesktopView .exploreOfferesContainer,
#offersTMView .exploreOfferesContainer {
    margin-top: 3%;
}

#offersDesktopView .exploreOfferesContainer img,
#offersTMView .exploreOfferesContainer img {
    width: 80%;
}

@media (max-width: 568px) {
    #offersTMView .exploreOfferesContainer img {
        width: 50%;
    }

    #offersTMView .exploreOfferesContainer {
        margin-top: 50px;
    }
}

#offersDesktopView .exploreOfferesContainer div,
#offersTMView .exploreOfferesContainer div {
    left: 40% !important;
    top: 58% !important;
}

#offersTMView .exploreOfferesContainer .exploreOfferesInnerContainerMobile {
    left: 50% !important;
}

@media (max-width: 1200px) {
    #offersDesktopView .exploreOfferesContainer img {
        width: 65%;
    }

    #offersDesktopView .exploreOfferesContainer div {
        left: 35% !important;
        top: 61% !important;
    }

    #offersDesktopView .exploreOfferesContainer p {
        font-size: 16px;
    }
}

#offersDesktopView .imgDiv {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#offersDesktopView .offerImgDiv {
    display: inline-block;
    margin-right: 60px;
}

@media (max-width: 1400px) {
    #offersDesktopView .offerImgDiv {
        margin-right: 10px;
    }
}


#offersDesktopView .offerImgDiv .offerImage,
#offersTMView .offerImgDiv .offerImage {
    width: 100%;
}



#offersDesktopView .offerImgDiv div,
#offersTMView .offerImgDiv div {
    top: 60%;
    padding-left: 7%;
    max-width: 75%;
}

@media (max-width: 1100px) {
    #offersDesktopView .offerImgDiv div {
        max-width: 73%;
    }
}

@media (max-width: 857px) {
    #offersTMView .offerImgDiv div {
        max-width: 73%;
    }
}


#offersDesktopView .offerImgDiv .offerStar,
#offersTMView .offerImgDiv .offerStar {
    margin-top: -4px;
    padding-right: 4px;
}

#offersDesktopView .offerImgDiv p,
#offersTMView .offerImgDiv p {
    margin-bottom: 0px;
    font-size: 14px;
}

@media (max-width: 1200px) {
    #offersDesktopView .offerImgDiv p {
        font-size: 12px;
    }
}

@media (max-width: 857px) {
    #offersTMView .offerImgDiv p {
        font-size: 12px;
    }
}

@media (max-width: 620px) {
    #offersTMView .offerImgDiv p {
        font-size: 11px;
    }
}


@media (max-width: 1100px) {
    #offersDesktopView .offerImgDiv p {
        font-size: 11px;
    }
}

#offersDesktopView .offerImgDiv h4,
#offersTMView .offerImgDiv h4 {
    margin-top: -35px;
    padding-left: 7%;
    font-weight: 700;
    color: #F40143;
}

@media (max-width: 1100px) {
    #offersDesktopView .offerImgDiv h4 {
        font-size: 18px;
        margin-top: -26px;
    }
}

@media (max-width: 620px) {
    #offersTMView .offerImgDiv h4 {
        font-size: 15px;
        margin-top: -23px;
    }
}


@media (min-width: 768px) {
    #offersTMView .row {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
    }
}

@media (max-width: 568px) {

    #offersTMView .textDiv h1 {
        font-size: 3.5rem;
    }

    #offersTMView .textDiv p {
        font-size: 1rem;
    }

    #offersTMView .offerImgDiv .offerImage {
        width: 90%;
    }

    #offersTMView .offerImgDiv {
        text-align: center;
    }

    #offersTMView .offerImgDiv p {
        text-align: left;
    }

    #offersTMView .offerImgDiv div {
        padding-left: 11%;
    }

    #offersTMView .offerImgDiv p {
        font-size: 14px;
    }

    #offersTMView .offerImgDiv h4 {
        font-size: 1.8rem;
        margin-top: -35px;
        padding-left: 11%;
    }
}

@media (max-width: 430px) {
    #offersTMView .offerImgDiv p {
        font-size: 12px;
    }

    #offersTMView .offerImgDiv h4 {
        font-size: 1.2rem;
        margin-top: -30px;
    }
}



#courseSection2 .courseSection2InnerSection1::-webkit-scrollbar,
#reviewSection .reviewInnerSection1::-webkit-scrollbar {
    display: none;
}

#courseSection2 .courseDescription2 {
    color: #808080;
    font-size: 0.75rem;
    font-weight: 400;
}

@media (min-width: 992px) {
    .w-lg-100 {
        width: 100%;
    }
}

@media (max-width: 992px) {

    #courseSection2 .courseSection2InnerSection1 {
        /* width: 95vw; */
        width: 100%;
        overflow-x: scroll;
    }

    #courseSection2 .courseSection2InnerSection2 {
        width: 150vw;
        white-space: nowrap;
        cursor: pointer;
    }

    #courseSection2 .courseSection2InnerSection3 {
        white-space: break-spaces;
    }
}

@media (max-width: 576px) {
    #courseSection2 .courseSection2InnerSection2 {
        width: 230vw;
    }
}


#learningSection .textDiv {
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    /* align-items: baseline; */
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 50vh;
}

#learningSection h1 {
    font-weight: 700;
    /* color: #1D3764; */
}

@media (max-width: 568px) {
    #learningSection .textDiv h1 {
        font-size: 2.18rem;
    }
}

#learningSection p {
    font-weight: 400;
    font-size: 1.12rem;
    text-align: center;
}

#learningSection .card {
    border: 0px;
    background: transparent;
    margin: 18px 0px;
}

#learningSection .card-img-overlay {
    max-width: 65%;
}

#learningSection .card h5 {
    font-weight: 400;
}

#learningSection .card h4 {
    font-weight: 700;
    padding-top: 10px;
}

@media (max-width: 568px) {
    #learningSection .homePageBtn {
        width: 55%;
    }
}


@media only screen and (max-width: 660px) and (min-width: 576px) {
    #learningSection .card h5 {
        font-size: 1.1em
    }

    #learningSection .card h4 {
        font-size: 1.3em
    }
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
    #learningSection .card h5 {
        font-size: 1.1em
    }

    #learningSection .card h4 {
        font-size: 1.3em
    }
}

#learningSection #desktopSection {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}




#reviewSection {
    text-align: center;
}


#reviewSection .heading {
    font-weight: 700;
    font-size: 3rem;
}

#reviewSection .subHeading {
    font-weight: 400;
    font-size: 1.12rem;
}

.reviewSectionHeading{
    font-weight: 700;
    font-size: 3rem;
}
.reviewSectionSubHeading{
    font-weight: 400;
    font-size: 1.12rem;
}

#reviewSection .reviewerDiv {
    background-size: cover;
    background-repeat: round;
    padding: 30px;
    text-align: left;
    margin-bottom: 15px;
}

#reviewSection .reviewerEDiv img {
    width: 40px;
    right: -13px !important;
    top: -16px !important;
}

@media (max-width: 1350px) {
    #reviewSection .reviewerEDiv img {
        width: 30px;
    }
}

#reviewSection .reviewerLDiv {
    display: flex;
    align-items: center;
}

#reviewSection .reviewerLDiv img {
    margin-top: -30px;
    margin-right: 10px;
    width: 50px;
}

#reviewSection .reviewerLDiv p {
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: 700;
    width: 80%;
}

#reviewSection .reviewerLDiv p span {
    font-weight: 400;
}

#reviewSection .reviewPara {
    margin-bottom: 0px;
}

#reviewSection .reviewerFrameDiv {
    padding: 0px;
}

#reviewSection .reviewerFrameDiv img {
    right: 20px !important;
    top: 20px !important;
}

#reviewSection .reviewerFrameImg {
    width: 100%;
}


@media (max-width: 855px) {
    #reviewSection .reviewerLDiv p {
        font-size: 0.8rem;
    }

    .reviewPara {
        font-size: 15px;
        line-height: 21px;
    }

    #reviewSection .reviewerEDiv img {
        width: 25px;
    }

    #reviewSection .reviewerLDiv img {
        width: 40px;
    }
}


@media (max-width: 768px) {
    #reviewSection .reviewInnerSection1 {
        /* width: 97vw; */
        width: 100%;
        overflow-x: scroll;
    }

    #reviewSection .reviewInnerSection2 {
        width: 200vw;
        white-space: nowrap;
    }

    #reviewSection .reviewInnerSection2 .reviewerDiv {
        white-space: break-spaces;
    }
}

@media (max-width: 1340px) {
    .homePageMainBannerContainer {
        margin: 0rem;
    }

    .homePageMainContainer {
        margin: 2rem;
    }
}


@media (max-width: 768px) {
    .homePageMainContainer {
        margin: 2rem 20px;
    }
    #reviewSection{
        margin: 2rem 0px;
    }
}