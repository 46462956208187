.countryCodeFlagPhoneNoViewMainContainer{
    display: flex;
    align-items: center;
}

/* Note : this class is defined in index */
.countryCodeFlagDropDownImg{
    margin-right: 8px;
    width: 10px;
}

.countryCodeFlagPhoneNoViewMainContainer input[type="tel"]{
    border-radius: 5px;
    border: 1px solid #E9E9E9;
    padding: 6px 15px;
    color: #252525;
    font-size: 15px;
}

.countryCodeFlagPhoneNoViewMainContainer input[type="tel"]:focus{
    outline: unset;
}

/* START : custom style for flag */
.css-hlgwow{
    padding: 2px 8px !important;
}
.css-1u9des2-indicatorSeparator{
    width: 0px !important;
}
.css-1xc3v61-indicatorContainer{
    padding: 8px 3px !important;
}
.css-8mmkcg{
    width: 17px !important;
}
/* END : custom style for flag */
