.commonProductPageAlignmentContainerHelper {
	margin: 0rem 10vw;
	max-width: 1340px;
	padding: 2rem 100px;
	text-align: left;
}

/* START : style for headerCardRegistrationMainContainer */
.coursePageHeaderCardRegistrationMainContainer {
	position: relative;
	--bs-gutter-x: 0rem;
}

.topBgOverlap {
	height: 0px;
	position: absolute;
	z-index: 0;
	top: 0;
	width: 100%;	
	/* Ref https://cssgradient.io/ */
    /* background: linear-gradient(90deg, rgba(0,0,0,1) 23%, #6f0031 48%, rgba(0,0,0,1) 70%); */
	/* background-color: #252525; */
}

.bgLinesOverlap{
	position: absolute;
	width: 100%;
	height: 2218px;
}

.bgBc1Overlap{
	position: absolute;
	width: 100%;
	height: 2218px;
}

.coursePageHeaderCardRegistrationMainContainer .lhs {
	margin-right: 40px;
}

.coursePageHeaderCardRegistrationMainContainer .lhs,
.coursePageHeaderCardRegistrationMainContainer .rhs {
	z-index: 1;
}

/* END : style for headerCardRegistrationMainContainer */

/* START : style for previewVideoLinkMainContainer */
.previewVideoLinkMainContainer {
	text-align: center;
	display: flex;
	justify-content: center;
}

/* END : style for previewVideoLinkMainContainer */

/* START : style for sampleCertificateMainContainer */
.sampleCertificateMainContainer {
	text-align: center;
}

/* END : style for sampleCertificateMainContainer */

@media (max-width: 1500px) {
	.commonProductPageAlignmentContainerHelper {
		padding: 2rem 60px;
	}
}

@media (max-width: 1340px) {
	.commonProductPageAlignmentContainerHelper {
		margin: 0rem;
		padding: 2rem 100px;
	}
}

@media (max-width: 992px) {
	.commonProductPageAlignmentContainerHelper {
		padding: 2rem 15px;
	}
	.previewVideoLinkMainContainer {
		padding: 1rem 0px !important;
	}
}

@media (max-width: 768px) {
	.commonProductPageAlignmentContainerHelper {
		padding: 1rem 15px;
	}

	/* START : style for imageCarouselMainContainer */
	.imageCarouselMainContainer {
		display: flex;
		justify-content: center;
	}
	/* END : style for imageCarouselMainContainer */
}
