.settingTabDisplayDetailsCouserLoaderMobile {
    display: none;
}
.settingTabTransactionsDetailsCouserLoaderMobile {
    display: none;
}


@media (max-width: 576px) {
    .settingTabDisplayDetailsCouserLoaderDesktop {
        display: none;
    }
    .settingTabDisplayDetailsCouserLoaderMobile {
        display: block;
    }

    .settingTabTransactionsDetailsCouserLoaderDesktop {
        display: none;
    }
    .settingTabTransactionsDetailsCouserLoaderMobile {
        display: block;
    }
}


