.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer {
    margin-top: 27px;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavriouteNavContainer {
    margin-left: 15px;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavriouteNavContainer .nav-link {
    background: transparent;
    border: 0px;
    text-align: center;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavriouteNavContainer .nav-link.active {
    border-bottom: 7px solid red;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer {
    background: white;
    padding: 20px;
    border-radius: 13px 22px;
    max-height: 990px;
    overflow-y: scroll;
}



.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer::-webkit-scrollbar {
    width: 8px;
}
.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}
.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer::-webkit-scrollbar-thumb:hover {
    background: gray;
}



.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainerEmpty {
    height: 540px;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .row {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .row img {
    cursor: pointer;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer p {
    margin-bottom: 5px;
    font-size: 1rem;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .videoTitle {
    font-weight: 500;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .videoCategoty,
.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .views {
    color: #8B8B8B;
}

.userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .emptyContainer {
    display: flex;
    justify-content: center;
    margin-top: 240px;
}


.historyFavriouteContainerLoaderMobile {
    display: none;
}

@media (max-width: 630px) {
    .userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .row {
        align-items: flex-start;
    }

    .userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .historyTextcontainer {
        padding-left: 0px;
        padding-right: 0px;
    }

    .userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .videoTitle {
        font-size: 0.9rem;
    }

    .userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer p {
        margin-bottom: 0px;
    }

    .userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .videoCategoty,
    .userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavouriteMaincontainer .views {
        font-size: 0.8rem;
    }

}


@media (max-width: 576px) {
    .userDashboardMainContainer .myLearningMainContainer .historyFavriouteContainer .historyFavriouteNavContainer {
        margin-left: 0px;
    }

    .historyFavriouteContainerLoaderDesktop {
        display: none;
    }

    .historyFavriouteContainerLoaderMobile {
        display: block;
    }

}