.tagsComponent {
    margin: 0px;
    border-radius: 5px;
    width: fit-content;
    margin-left: 10px;
    border: 0.5px solid;
    display: flex;
    align-items: center;
    width: initial;
}

.tagsComponent img {
    width: 14px;
    margin: 5px;
}

.tagsComponent span {
    margin-right: 7px;
    margin-left: 1px;
    font-weight: 500;
    text-align: left;
}



@media (max-width: 500px) {

    .tagsComponent span {
        font-size: 9px !important;
    }

    .tagsComponent img {
        width: 11px;
    }
}