.mainMobileConfirmOrderContainer{
    margin: 2rem 4vw;
}


.mainMobileConfirmOrderContainer .headContainer{    
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mainMobileConfirmOrderContainer .headContainer img{
    width: 100px;
}
.mainMobileConfirmOrderContainer .headContainer p{
    font-size: 25px;
    font-weight: 500;
    color:#1A73E9
}


.mainMobileConfirmOrderContainer .pdfHolder {
    border-radius: 5px;
    padding: 8px;
    background: #F3F3F3;
    display: flex;
    align-items: center;    
    justify-content: space-between;
    width: fit-content;
    cursor: pointer;
    width: 100%;
    margin-bottom: 28px;
}
.mainMobileConfirmOrderContainer .pdfHolder .PDFIcon {
    width: 29px;
}
.mainMobileConfirmOrderContainer .pdfHolder .DownloadIcon {
    width: 22px;
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 {
    margin: 0px 20px 0px 10px;
    text-align: left;
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 p {
    margin-bottom: 0px;
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 .title {
    color: #324054;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    display: inline-block;
    margin-left: 10px;    
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 .subTitle {
    color: #71839B;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.055px;
    margin-left: 39px;
    margin-top: -3px;    
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 .subTitle span {
    color: #71839B !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    /* 145.455% */
    letter-spacing: -0.055px !important;
}



.mainMobileConfirmOrderContainer .transactionDetails{
    display: flex;
    justify-content: space-between;
}
.mainMobileConfirmOrderContainer .transactionDetails .text{
    font-size: 14px;
    font-weight: 500;
    color: black;
}
.mainMobileConfirmOrderContainer .transactionDetails .value{
    font-size: 14px;
    font-weight: 400;
    color:#606060;
}
.mainMobileConfirmOrderContainer{
    margin: 2rem 4vw;
}


.mainMobileConfirmOrderContainer .headContainer{    
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mainMobileConfirmOrderContainer .headContainer img{
    width: 100px;
}
.mainMobileConfirmOrderContainer .headContainer p{
    font-size: 25px;
    font-weight: 500;
    color:#1A73E9
}


.mainMobileConfirmOrderContainer .pdfHolder {
    border-radius: 5px;
    padding: 8px;
    background: #F3F3F3;
    display: flex;
    align-items: center;    
    justify-content: space-between;
    width: fit-content;
    cursor: pointer;
    width: 100%;
    margin-bottom: 28px;
}
.mainMobileConfirmOrderContainer .pdfHolder .PDFIcon {
    width: 29px;
}
.mainMobileConfirmOrderContainer .pdfHolder .DownloadIcon {
    width: 22px;
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 {
    margin: 0px 20px 0px 10px;
    text-align: left;
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 p {
    margin-bottom: 0px;
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 .title {
    color: #324054;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    display: inline-block;
    margin-left: 10px;    
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 .subTitle {
    color: #71839B;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.055px;
    margin-left: 39px;
    margin-top: -3px;    
}
.mainMobileConfirmOrderContainer .pdfHolder .div_01 .subTitle span {
    color: #71839B !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    /* 145.455% */
    letter-spacing: -0.055px !important;
}

.mainMobileConfirmOrderContainer .orderConfirmedText{
    color: #1573FE;
    font-size: 25px;
    font-weight: 600;
}

.mainMobileConfirmOrderContainer .continueToDashboardBtn{
    margin-top: 10px;
}
.mainMobileConfirmOrderContainer .continueToDashboardBtn img{
    width: 16px;
    margin-left: 8px;
}