@media (max-width: 768px) {
    .contentLoaderForProductPageScreenGreaterThen576{
        display: block;
    }
    
    .contentLoaderForProductPageScreenLesserThen576{
        display: none;
    }    
}

@media (max-width: 468px) {
    .contentLoaderForProductPageScreenGreaterThen576{
        display: none;
    }
    
    .contentLoaderForProductPageScreenLesserThen576{
        display: block;
    }    
}


