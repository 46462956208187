.examPageBgHolder .examPageHeadingContainer .resultMatrixContainer{
    display: flex;
    font-size: 0.8rem;
}

.examPageBgHolder .examPageHeadingContainer .resultMatrixContainer .leftContent{
    min-width: 120px;
}

.examPageBgHolder .examPageHeadingContainer .resultMatrixContainer .rightContent{
    color: white;
    border-radius: 7px;
    min-width: 70px;
    text-align: center;
}


.examPageMainContainer .resultPageQuestionContainer{
    padding: 1rem 1rem 0rem 1rem;
    background-color: white;
    border: 1px solid #252525;
    border-radius: 5px;
    margin-bottom: 25px;
}

.examPageMainContainer .resultPageQuestionContainer .question{
    margin-top: 10px;
    margin-bottom: 1.3rem;
}

.examPageMainContainer .resultPageQuestionContainer .missingBadgeContainer{
    width: 92px;
    border: 0px;
    background: #FFE9E9;
    padding: 5px 6px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 5px;
}

.examPageMainContainer .resultPageQuestionContainer .missingBadgeContainer p{
    margin-bottom: 0px;
    font-size: 0.8rem;
}

.examPageMainContainer .resultPageQuestionContainer .missingBadgeContainer img{
    width: 17px;
}

.examPageMainContainer .resultPageQuestionContainer .option{
    padding: 10px;
}


.examPageMainContainer .resultPageQuestionContainer .resultRadioBtn,
.examPageMainContainer .resultPageQuestionContainer .resultRadioBtnLabel{
    opacity: 1 !important;
    margin-left: 0px;
    margin-right: 10px;
}

.examPageMainContainer .resultPageQuestionContainer .selectedRadioBtn input{
    background: black;
    border: 0px;
}

.examPageMainContainer .resultPageQuestionContainer .answerIsCorrect{
    background: #E6FFE5;
}

.examPageMainContainer .resultPageQuestionContainer .answerIsInCorrect{
    background: #FFE5E5;
}  

.examPageMainContainer .resultPageQuestionContainer .rightAnswerIcon{
    float: right;
    width: 18px;
    margin-top: 5px;
    margin-right: 10px;
}

.examPageMainContainer .resultPageQuestionContainer .wrongAnswerIcon{
    float: right;
    width: 15px;
    margin-top: 3px;
    margin-right: 10px;
}

