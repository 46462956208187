.claimCertificateClaimPopupMainContainer .modal-content {
    border-radius: 10px;
    background: #f4f4f4;
}

.claimCertificateClaimPopupMainContainer .modal-header {
    border: 0px;
    padding-bottom: 0px;
}

.claimCertificateClaimPopupMainContainer .modal-title .t1,
.claimCertificateClaimPopupMainContainer .modal-title .t2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.03em;
    margin-bottom: 5px;
}

.claimCertificateClaimPopupMainContainer .modal-title .t2 {
    background: linear-gradient(91.55deg, #FF7B37 6.91%, #FF3B37 19.85%, #FF359D 29.64%, #CA56F6 39.46%, #8D98FF 48.63%, #75CDFF 57.86%, #75FC7C 66.69%);
    -webkit-background-clip: text;
    color: transparent;
}

.claimCertificateClaimPopupMainContainer .modal-body .row_x {
    --bs-gutter-x: 1rem;
}

.claimCertificateClaimPopupMainContainer .modal-body .certificateContainer {
    border-radius: 6px;
    border: 2px solid #e6e6e6;
    /* border: 1px solid red; */
    padding: 0px;
    cursor: pointer;
}

.claimCertificateClaimPopupMainContainer .modal-body .blueBorder {
    border: 1px solid #1573FE;
}

.claimCertificateClaimPopupMainContainer .modal-body .certificateContainer img {
    margin-bottom: 7px;
}

.claimCertificateClaimPopupMainContainer .modal-body .certificateContainer .title,
.claimCertificateClaimPopupMainContainer .modal-body .certificateContainer .price {
    padding: 0px 5px;
    margin-bottom: 3px;
}

.claimCertificateClaimPopupMainContainer .modal-body .certificateContainer .title {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
}

.claimCertificateClaimPopupMainContainer .modal-body .certificateContainer .price {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
}

.claimCertificateClaimPopupMainContainer .modal-footer {
    border: 0px;
}

.claimCertificateClaimPopupMainContainer .modal-footer button {
    background: #1573FE;
    color: white;
    border: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 5px;
}

.claimCertificateClaimPopupMainContainer .modal-footer img {
    width: 15px;
    margin-left: 7px;
}


/* To hide the horizontal scrollbar */
.claimCertificateClaimPopupMainContainer .modal-body::-webkit-scrollbar {
    display: none;
}

/* Optional: Firefox scrollbar */
.claimCertificateClaimPopupMainContainer .modal-body {
    scrollbar-width: none;
}

@media (max-width: 568px) {
    .claimCertificateClaimPopupMainContainer .modal-dialog {
        align-items: flex-end;
        margin: 1rem 0rem 0rem 0rem;
    }

    .claimCertificateClaimPopupMainContainer .modal-content {
        border-radius: 10px 10px 0px 0px;
    }

    .claimCertificateClaimPopupMainContainer .modal-body {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
    }

    .claimCertificateClaimPopupMainContainer .modal-body .row_x {
        width: 140%;
    }
}


@media (max-width: 410px) {
    .claimCertificateClaimPopupMainContainer .modal-body .certificateContainer .title {
        font-size: 11px;
    }

    .claimCertificateClaimPopupMainContainer .modal-body .certificateContainer .price {
        font-size: 13px;
    }
}