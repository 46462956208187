.courseHeadMainContainer {
  color: black;
  color: white;
  padding-bottom: 30px;
}

.courseHeadMainContainer .courseDescription {
  margin: 30px 0px;
}

.courseHeadMainContainer .subPointers ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.courseHeadMainContainer .subPointers ul li {
  margin-bottom: 5px;
}

.courseHeadMainContainer .subPointers ul li::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  margin-top: -3px;
  width: 20px;
}

@media (max-width: 992px) {
  .courseHeadMainContainer {
    padding-bottom: 10px;
  }
  .courseHeadMainContainer .title {
    font-size: 22px;
    
  }

  .courseHeadMainContainer .courseDescription {
    font-size: 15px;
    margin: 20px 0px;
  }
  .courseHeadMainContainer .subPointers ul {
    font-size: 15px;
  }
}


@media (max-width: 768px) {
    .courseHeadMainContainer{
        margin-top: 20px;
    }
    .courseHeadMainContainer .title {
        font-weight: 700;
      }    
}
