/* START : Series Card Helper */
.seriesContainerC{
    width: 98%;
}
.seriesContainerC .seriesCard {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 10px;
    border: 0.5px solid #EDEDED;
    z-index: 99;
    background: #F8F8F8;
    /* background: red; */
}

@media (min-width: 1200px) {
    .seriesContainerC .shineOnCourseCard img{
        width: 100% !important;
    }
}
/* END : Series Card Helper */
