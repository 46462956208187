.homePageConfirmOrderView .mainMobileConfirmOrderContainer {
    margin: 0px 5px !important;
}

.homePageConfirmOrderView .mainMobileConfirmOrderContainer .headContainer {
    height: 290px !important;
}

.homePageConfirmOrderView .continueToDashboardBtn {
    margin: 1rem;
}

.homePageConfirmOrderView .continueToDashboardBtn img {
    width: 16px;
    margin-left: 8px;
}