.allCommentsContainer  .col-profileImgPlaceholder{
    margin-right: 3px;
    width: 4%;    
    padding-left: 0px;
}

.allCommentsContainer .col-commentContainer{
    width: 94%;    
}

.allCommentsContainer .commenterName{
    margin-bottom: 1px;
    font-size: 0.9rem;
}

.allCommentsContainer .commentTime{
    font-size: 0.7rem;
    color: #CECECE;
    margin-left: 10px;
}

.allCommentsContainer .comment{
    margin-bottom: 6px;
    font-size: 0.8rem;
}
.allCommentsContainer .commentInteractionContainer{
    display: flex;
    font-size: 0.7rem;
    height: 25px;
}
.allCommentsContainer .commentLikeContainer{
    margin-right: 7px;
}

.allCommentsContainer .commentLikeContainer i{
    margin-right: 4px;
    cursor: pointer;
}

.allCommentsContainer .commentDislikeContainer{
    margin-right: 10px;
    cursor: pointer;
}

.allCommentsContainer .commentReplyBtn{
    background: transparent;
    border: 0px;
    height: fit-content;
}

.allCommentsContainer .commentReplyBtn:hover{
    color: grey;
}

.allCommentsContainer  .col-profileImgPlaceholder img{
    border-radius: 20px;
}

@media (max-width: 470px) {
    .allCommentsContainer  .col-profileImgPlaceholder{
        width: 7%;    
    }
    .allCommentsContainer .col-commentContainer{
        width: 92%;    
    }    
}
