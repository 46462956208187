.etPopup .modal-title,
.etPopup .modal-body {
    color: #252525;
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
}

.etPopup .modal-header {
    border-bottom: 0px;
    padding: 1rem 1rem 0rem 1rem;
}

.etPopup .modal-header button{
    opacity: 1;
    font-size: 12px;
}

.etPopup .modal-body{
    padding: 1rem 1rem 0rem 1rem;
    font-weight: 400;
}

.etPopup .modal-footer {
    justify-content: center;
    border-top: 0px;
    padding: 0rem 1rem 1rem 1rem;
}

.etPopup .modal-footer .cancelBtn,
.etPopup .modal-footer .okBtn{
    font-size: 15px;
    font-weight: 400;
    line-height: normal;    
    border-radius: 7px;
    padding: 2px 0px;
}

.etPopup .modal-footer .cancelBtn{
    color: #1573FE;
    border: 1px solid #1573FE;    
    background: #F4F4F4;        
}

.etPopup .modal-footer .okBtn{
    color: white;
    background: #252525;    
    border: 0px;
}