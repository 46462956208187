.invoiceForm{
    width: 16cm;
    height: 22.25cm;
    padding: 1cm 1cm;
    font-family: Arial;
    
    /* letter-spacing: 0.2px; */
    line-height: 1.1;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    color: '#373435';
    font-size: 12px;

    h3{
        font-size: 20px;
        font-weight: 700;
        color:#0f62ac;
        margin:0%;
    }

    table{
        display: table;
        /* border-color: #a9abae; */
        table-layout:fixed;
    }
    
    td{
        line-height: 1.1;
        padding: 7px ;
        /* border-color: #a9abae; */
        /* border-top: 0.5px; */
        /* word-wrap:break-word; */
        text-wrap:wrap;
        /* word-break: break-all; */
    }
    tr{
        display: table-row;
        border:0px;
        /* border-color: #a9abae; */
    }

    tr:nth-child(1)>td{
      /* border-top: 1px solid grey; */
    }
    
    tr td:nth-child(2) {
        border: 0px;
        /* font-size: 11px; */
        font-weight: 700;
    }
    tr td:nth-child(1) {
        width:85%;
        padding-right:8px;
        padding-left:10px;
        text-overflow: wrap;
        border: 0px;
    }
    table td, table td * {
        vertical-align: top;
    }

    
}


.timesFont{
font-family: 'Times New Roman';
}

.calibriFont{
    font-family: sans-serif;
}

.arialFont{
    font-family:Arial;
}