.mainConfirmOrderContainer {
	margin: 2rem 10vw;
	max-width: 1340px;
	text-align: left;
}

.mainConfirmOrderContainer .confirmOrderInfoContainer p {
	margin-bottom: 7px;
}

.mainConfirmOrderContainer .confirmOrderInfoContainer .heading {
	font-weight: 500;
}

.mainConfirmOrderContainer .confirmOrderInfoContainer .value {
	color: #606060;
}

.mainConfirmOrderContainer .confirmOrderDetailsContainer .heading {
	font-size: 1.125rem;
	font-weight: 500;
	margin-bottom: 2px;
}

.mainConfirmOrderContainer .confirmOrderDetailsContainer .description {
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 8px;
}

.mainConfirmOrderContainer .confirmOrderDetailsContainer .priceContainer {
	text-align: right;
}

.mainConfirmOrderContainer #downloadPaymentRecepit {
	background: #252525;
	border-radius: 4px;
	color: white;
	border: 0px;
	font-size: 14px;
	padding: 5px 55px;
}

@media (max-width: 1340px) {
	.mainConfirmOrderContainer {
		margin: 2rem;
	}
}

@media (max-width: 768px) {
	.mainConfirmOrderContainer {
		margin: 2rem 4vw;
	}
}

@media (max-width: 576px) {
	.mainConfirmOrderContainer
		.confirmOrderDetailsContainer
		.textContainer
		.heading {
		font-size: 1.1rem;
	}

	.mainConfirmOrderContainer
		.confirmOrderDetailsContainer
		.textContainer
		.description {
		font-size: 1rem;
	}

	.mainConfirmOrderContainer
		.confirmOrderDetailsContainer
		.textContainer
		.priceTM {
		font-size: 1.1rem;
	}
}
