.installmentView .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 0px 10px 0px;
}

.installmentView .header p {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 0px;
}

.installmentView .header button {
	background: transparent;
	border: 0px;
	color: #d2d2d2;
	font-size: 22px;
	font-weight: 600;
	height: fit-content;
}

/* START: style for withoutInstallment */
.installmentView .withoutInstallment .content {
	display: flex;
	align-items: center;
}

.installmentView .withoutInstallment .content img {
	width: 96px;
	margin-right: 13px;
}

.installmentView .withoutInstallment .content .title {
	font-weight: 500;
	margin-bottom: 5px;
	line-height: 19.36px;
}

.installmentView .withoutInstallment .content .viewDetails {
	text-decoration: underline;
	color: #858585;
	font-weight: 700;
	font-size: 11px;
	line-height: 13.31px;
	cursor: pointer;
}
/* END: style for withoutInstallment */

/* START: style for withInstallment */
.installmentView .withInstallment .content {
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.installmentView .withInstallment .content .contentInnerContainer {
	border: 1px solid #b9b9b9;
	border-radius: 10px;
	padding: 10px 15px;
	margin-bottom: 15px;
	cursor: pointer;
}

.installmentView
	.withInstallment
	.content
	.contentInnerContainer
	.content1
	.p1 {
	margin-bottom: 0px;
	font-size: 20px;
	font-weight: 700;
}

.installmentView
	.withInstallment
	.content
	.contentInnerContainer
	.content1
	.p2 {
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 4px;
}

.installmentView
	.withInstallment
	.content
	.contentInnerContainer
	.content2
	.innerContent2 {
	display: flex;
	justify-content: space-between;
}

.installmentView
	.withInstallment
	.content
	.contentInnerContainer
	.content2
	.innerContent2
	p {
	margin-bottom: 4px;
}

/* END: style for withInstallment */

.installmentView .actionBtn {
	border-radius: 5px;
	width: 100%;
	background: #1573fe;
	border: 0px;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: -0.15px;
	padding: 6px 10px;
}

.installmentView .withoutInstallment .actionBtn {
	margin-top: 24px;
}

.installmentView .withInstallment .actionBtn {
	margin-top: 10px;
}

.installmentView .actionBtn img {
	width: 16px;
	margin-left: 8px;
}

/* START : style for desktop */
@media (min-width: 768px) {
	.installmentView .withoutInstallment .header {
		display: none;
	}

	.installmentView .withInstallment .header {
		display: none;
	}

	.installmentView .withoutInstallment .installmentCallContainer {
		position: fixed;
		bottom: 40px;
		width: 370px;
	}
	.installmentView .withoutInstallment .actionBtn,
	.installmentView .withInstallment .actionBtn {
		position: fixed;
		bottom: 10px;
		width: 370px;
	}
	.installmentView
		.withInstallment
		.content
		.contentInnerContainer
		.content1
		.p1 {
		font-size: 18px;
	}
}
/* END : style for desktop */

@media (max-width: 768px) {
	.installmentView .header button {
		display: none;
	}
}

@media (max-width: 568px) {
	.installmentView .withoutInstallment .content .title {
		font-size: 16px;
	}
}
