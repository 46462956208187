.seriesView .heading {
  font-weight: 600;
  font-size: 23px;
}

/* START : series style*/

.seriesView .seriesAccordionBtn {
    font-size: 18px;
    font-weight: 600;
    padding: 16px 40px;
    box-shadow: unset;    
    background: transparent;
}

.seriesView .seriesAccordionBtn::after {
    position: absolute;
    left: 11px;
}

.seriesView .seriesAccordionBtn:not(.collapsed) {
    color: black;
}

.seriesView .seriesCollapse{
    margin-top: 8px;
}
/* END : series style*/




/* START : section style */
.seriesView .sectionNum{
    font-size: 16px;
    font-weight: 600;
    color: #575757;
    margin-right: 5px;
    padding: 0px 10px;
}

.seriesView .sectionAccordionBtn {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 40px;
    box-shadow: unset;    
    margin-top: 5px;
    background: transparent;
}

.seriesView .sectionAccordionBtn::after {
    position: absolute;
    left: 11px;
}

.seriesView .sectionAccordionBtn:not(.collapsed) {
    color: black;
    background-color: #F2F2F2;
}

.seriesView .sectionHr{
    margin: 10px 0px;
}
/* END : section style */



/* END : sub section style */
.seriesView .subSectionContainer{
    display: flex;
    align-items: center;
    padding: 7px 10px;
    margin: 7px 0px;    
}
.seriesView .subSectionContainer:hover{
  background-color: #E4F2F8;
}

.seriesView .videoIcon{
    width: 16px;
}

.seriesView .subSectionTitle{
    margin-left: 18px;
    margin-bottom: 0px;
}
/* END : sub section style */


@media (max-width: 768px) {
  .seriesView .heading {
    font-size: 21px;
  }

}

@media (max-width: 576px) {
  .seriesView .heading {
    font-size: 19px;
  }
  .seriesView .seriesAccordionBtn {
    font-size: 17.5px;
    padding: 8px 40px;
  }
}
