#videoPlayer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

#videoPlayer:fullscreen{
  height: 100vh!important;
  width: 100vw!important;
  flex: 1;
  padding-top: env(safe-area-inset-top);

}

#videPlayerInnerContainer {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
  /* background: red; */
  /* display: none; */
}

#videPlayerInnerContainer:fullscreen {
  width: 100vw;
  height: 100vh;
}

#videPlayerInnerContainer #div0 {
  top: 10px;
  position: absolute;
  right: 13px;
}

#videPlayerInnerContainer #div0 i {
  font-size: 22px;
  color: white;
}

#videPlayerInnerContainer #div1_d {
  height: inherit;
  width: 100%;
}


#videPlayerInnerContainer #div0_1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#videPlayerInnerContainer #div0_1 img {
  width: 50px;
}


.customeFadeinoutX {
  opacity: 0;
  -webkit-animation: customeFadeoutX 0.5s linear;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: customeFadeoutX 0.5s linear;
  /* Firefox < 16 */
  -ms-animation: customeFadeoutX 0.5s linear;
  /* Internet Explorer */
  -o-animation: customeFadeoutX 0.5s linear;
  /* Opera < 12.1 */
  animation: customeFadeoutX 0.5s linear;
}


@keyframes customeFadeoutX {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.customeFadeinout {
  animation: customeFadeinout 3s linear;
}

@keyframes customeFadeinout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


#videPlayerInnerContainer #div1 {
  display: flex;
  flex-wrap: nowrap;
  /* background-color: gray; */
  /* width: 100%; */
}


.rightTickImgToggle {
  display: none;
}

#videPlayerInnerContainer #div1 #videoSpeedContainer,
#videPlayerInnerContainer #div1 #qualityContainer {
  background: #252525;
  color: white;
  padding: 7px 13px;
  border-radius: 5px;
  /* display: none; */
}


#videPlayerInnerContainer #div1 #qualityContainer {
  width: 80px;
}

#videPlayerInnerContainer #div1 #videoSpeedContainer p,
#videPlayerInnerContainer #div1 #qualityContainer p {
  margin-bottom: 2px;
  font-size: 0.7rem;
  cursor: pointer;
  text-align: right;
}

#videPlayerInnerContainer #div1 #videoSpeedContainer img,
#videPlayerInnerContainer #div1 #qualityContainer img {
  width: 8px;
  margin-right: 8px;
}


#videPlayerInnerContainer #div3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: green; */
  /* width: 100%; */
}

#videoPlayer #playPauseButton,
#videoPlayer #backward10,
#videoPlayer #forward10,
#videoPlayer #volumeButton,
#videoPlayer #qualityButton,
#videoPlayer #ccButton,
#videoPlayer #expandShrinkButton,
#videoPlayer #speedUp {
  background: transparent;
  border: 0px;
}

#videoPlayer #playPauseButton img,
#videoPlayer #backward10 img,
#videoPlayer #forward10 img,
#videoPlayer #volumeButton img,
#videoPlayer #qualityButton img,
#videoPlayer #ccButton img,
#videoPlayer #expandShrinkButton img,
#videoPlayer #speedUp img {
  width: 25px;
}


#videoTime {
  color: white;
  display: inline;
  font-size: 13px;
  margin-left: 8px;
}

#remaningVideoTime {
  margin-right: 3px;
}

#completeVideoTime {
  margin-left: 3px;
}


#div2 {
  width: 100%;
  margin-bottom: 5px;
}

#timeBar {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background-image: linear-gradient(#ff4500, #ff4500);
  /* background-size: 0% 100%; */
  background-repeat: no-repeat;
  cursor: pointer;
  /* background: rgba(255, 255, 255, 0.6);
    margin-right: 15px; */
}

/* Input Thumb */
#timeBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #D9D9D9;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}



#volumeBar {
  -webkit-appearance: none;
  width: 65px;
  height: 3px;
  border-radius: 5px;
  background: rgba(132, 131, 131, 0.6);
  background-image: linear-gradient(#ffffff, #ffffff);
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  cursor: pointer;
}

/* Input Thumb */
#volumeBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #D9D9D9;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}





#vimeo-player-container {
  position: relative;
}

#vimeo-player {
  width: 100%;
}

#custom-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
}


commentvideoduration {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}


/* RangeSlider.css */

/* Hide the default range input thumb */
.custom-range::-webkit-slider-thumb {
  display: none;
}

.custom-range::-moz-range-thumb {
  display: none;
}

.custom-range::-ms-thumb {
  display: none;
}

.videPlayerInnerContainer_Shadow{
  background: linear-gradient(180deg, rgba(46, 46, 46, 0.00) 81.25%, rgba(46, 46, 46, 0.70) 100%);
}

@media (max-width: 430px) {
  #videPlayerInnerContainer #div0_1 img {
    width: 35px;
  }
}

.fullScreenBlackDiv{
  background-color: #000000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  height: 200% !important;
  z-index: 1020;
}

.fullScreenMiddleDiv{
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  z-index: 1509;
/*   
  height: 100vh!important;
  width: 100vw!important; */
  flex: 1;  
}