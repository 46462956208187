.seriesCard {
    background-color: white;
    display: flex;
    align-items: center;
    width: 49%;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.seriesCardOddMargin {
    margin-left: 7.5px;
}

.seriesCardEvenMargin {
    margin-right: 7.5px;
}

.seriesCard .seriesTitle {
    color: #252525;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
}

.seriesCard .timerContainer {
    align-items: center;
    display: flex;
    font-size: 0.8rem;
    margin-top: 7px;
    margin-bottom: 8px;
}

.seriesCard .timerContainer img {
    width: 16px;
    margin-right: 6px;
}





.seriesCard .seriesInnerContainer {
    padding-left: 15px;
}

.seriesCard .seriesInnerContainer .progressStatus p {
    font-size: 13px;
    margin-bottom: 5px;
}




/* START : Progress bar */
.seriesCard .seriesInnerContainer .seriesProgressBar {
    width: 100%;
    position: relative;
    top: 12px;
    transform: translateY(-50%);
    overflow: hidden;
    border-radius: 12px;
    height: 23px;

}

.seriesCard .seriesInnerContainer .seriesProgressBar .childX {
    width: 100%;
    height: 100%;
}

.seriesCard .seriesInnerContainer .seriesProgressBar .progressX {
    background: #20D160;
    background: linear-gradient(90deg, #F82B22 0%, #F8F202 50.98%, #64A134 100%);
    /* background: linear-gradient(90deg, #64A134 0%, #F8F202 48.51%, #F82B22 100%); */
}

.seriesCard .seriesInnerContainer .seriesProgressBar .shrinkerX {
    background-color: #E4E7EC;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.seriesCard .seriesInnerContainer .seriesProgressBar .timelapseX {
    /* width : 70%; */
    transition: 0.3s ease;
}

/* END : Progress bar */

@media (max-width: 992px) {
    .seriesCard {
        width: 100%;
    }

    .seriesCardOddMargin {
        margin-left: 0px;
    }

    .seriesCardEvenMargin {
        margin-right: 0px;
    }
}



@media (max-width: 650px) {
    .seriesCard .seriesInnerContainer p {
        margin-bottom: 6px;
    }

    .seriesCard .seriesInnerContainer .progressStatus p {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .seriesCard .timerContainer {
        font-size: 11px !important;
        margin-bottom: 0px;
    }

    .seriesCard .timerContainer img {
        width: 14px;
    }
}


@media (max-width: 550px) {
    .seriesCard .seriesInnerContainer p {
        font-size: 15px;
    }
}

@media (max-width: 450px) {
    .seriesCard .seriesInnerContainer p {
        font-size: 13px;
    }

    .seriesCard .seriesInnerContainer .progressStatus p {
        font-size: 10px;
    }
}