/* START : Desktop Style */
.offerView .desktopRelatedCourse {
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.offerView .desktopRelatedCourse .courseCardDiv {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.offerView .desktopRelatedCourse .courseCardDiv .div1 {
	display: flex;
	align-items: center;
}

.offerView .desktopRelatedCourse .courseCardDiv .div1 .thumbnail {
	width: 85px;
}

.offerView .desktopRelatedCourse .courseCardDiv .div1 .name {
	width: 240px;
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: 500;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

.offerView .desktopRelatedCourse .courseCardDiv .div1 .price {
	font-weight: 500;
}

.offerView .desktopRelatedCourse .courseCardDiv .pluseBtn {
	width: 25px;
	cursor: pointer;
}

.offerView .desktopRelatedCourse .courseCardDiv .crossedPrice {
	margin-right: 10px;
	margin-left: 10px;
	color: #878787;
	font-size: 16px;
	font-weight: 400;
}

.offerView .desktopRelatedCourse .courseCardDiv .offerPrice {
	color: #388e3c;
	font-size: 13px;
	font-weight: 600;
}

/* END : Desktop Style */

.offerView .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px 0px 0px;
}

.offerView .header p {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 0px;
}

.offerView .headerNote {
	font-size: 14px;
	font-weight: 400;
}

.offerView .header button {
	background: transparent;
	border: 0px;
	color: #d2d2d2;
	font-size: 22px;
	font-weight: 600;
	height: fit-content;
}

.offerView .header .green {
	color: #72ae17;
}

/* START for contentForShowOffersView */
.offerView .contentForShowOffersView {
	margin-bottom: 20px;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.offerView .contentForShowOffersView .offerContainer {
	border: 1.5px solid #0000001a;
	/* border: 1px solid black; */
	border-radius: 6px;
	margin-right: 20px;
	cursor: pointer;
}

.offerView .contentForShowOffersView .offerContainer .textContent {
	margin-top: 7px;
	padding: 0px 8px;
}

.offerView .contentForShowOffersView .offerContainer .title {
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 4px;
}

.offerView .contentForShowOffersView .offerContainer .discount {
	font-size: 16px;
	font-weight: 550;
	margin-bottom: 5px;
}

.offerView .contentForShowOffersView .offerContainer .discription {
	font-size: 13px;
	color: #878787;
	font-weight: 400;
}

/* END for contentForShowOffersView */

/* START : Style for contentAddNewCourseView */
.offerView .contentAddNewCourseView {
	margin-bottom: 20px;
}

.offerView .contentAddNewCourseView .relatedCourse {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.offerView .contentAddNewCourseView .relatedCourse .div1 {
	margin-right: 14px;
	text-align: center;
}

.offerView .contentAddNewCourseView .relatedCourse .div1 .pluseBtn {
	width: 30px;
	cursor: pointer;
	margin-top: -30px;
}

.offerView .contentAddNewCourseView .priceNote {
	color: #949494;
	font-size: 13px;
	font-weight: 400;
	margin-bottom: 0px;
}

.offerView .contentAddNewCourseView .priceContainer {
	margin-bottom: 0px;
}

.offerView .contentAddNewCourseView .priceContainer .discountedPrice {
	font-size: 17px;
	font-weight: 500;
}

.offerView .contentAddNewCourseView .priceContainer .actualPrice {
	font-size: 15px;
	font-weight: 400;
	color: #878787;
	text-decoration: line-through;
}

.offerView .contentAddNewCourseView .priceContainer .discountedPercentage {
	color: #388e3c;
	font-size: 13px;
	font-weight: 600;
}

/* END : Style for contentAddNewCourseView */

.offerView .actionBtn {
	border-radius: 5px;
	width: 100%;
	background: #1573fe;
	border: 0px;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: -0.15px;
	padding: 6px 10px;
}

.offerView .actionBtn img {
	width: 16px;
	margin-left: 8px;
}

.offerView .disableActionBtn {
	background: #888585;
	cursor: not-allowed;
}

@media (max-width: 768px) {
	.offerView .header button {
		display: none;
	}
}
@media (max-width: 568px) {
	.offerView .contentForShowOffersView .offerContainer .title {
		font-size: 11px;
	}

	.offerView .contentForShowOffersView .offerContainer .discount {
		font-size: 14px;
	}
}

@media (max-width: 430px) {
	.offerView .contentForShowOffersView .offerContainer {
		width: 50% !important;
	}

	.offerView .contentAddNewCourseView .relatedCourse .div1 {
		width: 35% !important;
	}
}

/* START : style for desktop */
@media (min-width: 768px) {
	.offerView .contentForShowOffersView {
		overflow: scroll !important;
		display: block !important;
	}

	.offerView .header {
		padding: 0px;
		margin-top: -8px;
	}

	.offerView .contentForShowOffersView .offerContainer {
		width: 100% !important;
		display: flex;
		align-items: center;
		margin-bottom: 15px;
	}

	.offerView .contentForShowOffersView .offerContainer .offerThumbnail {
		width: 85px !important;
		margin-left: 7px;
	}

	.offerView .contentForShowOffersView .offerContainer .title {
		margin-bottom: -2px;
	}

	.offerView .contentForShowOffersView .offerContainer .discount {
		margin-bottom: 1px;
	}

	.offerView .header .closeBtn {
		display: none;
	}

	.offerView .bottomPriceNoteContainer {
		position: fixed;
		bottom: 60px;
	}

	.offerView .actionBtn {
		position: fixed;
		bottom: 10px;
		width: 370px;
	}
}

/* END : style for desktop */
