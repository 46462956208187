/* START : Desktop Style */
.coursesHolderDesktopCartView {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.coursesHolderDesktopCartView .courseCardDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.coursesHolderDesktopCartView .courseCardDiv .div1 {
  display: flex;
  align-items: center;
}
.coursesHolderDesktopCartView .courseCardDiv .div1 .thumbnail {
  width: 85px;
}
.coursesHolderDesktopCartView .courseCardDiv .div1 .name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}
.coursesHolderDesktopCartView .courseCardDiv .crossedPrice{
  margin-right: 10px;
  margin-left: 10px;
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}
.coursesHolderDesktopCartView .courseCardDiv .offerPrice{
  color: #388E3C;
  font-size: 13px;
  font-weight: 600;
}
.coursesHolderDesktopCartView .courseCardDiv .delete {
  width: 17px;
  cursor: pointer;
}
/* END : Desktop Style */




.coursesHolderMobileCartView {
  margin-bottom: 35px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.coursesHolderMobileCartView .div1 {
  margin-right: 14px;
}

.coursesHolderMobileCartView .courseCardDiv {
  position: relative;
}

.coursesHolderMobileCartView .courseCardDiv {
  position: relative;
}

.coursesHolderMobileCartView .courseCardDiv .whiteCrossBtn {
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.coursesHolderMobileCartView .courseCardDiv .overlay {
  background: black;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.5;
  border-radius: 8px;
}

@media (max-width: 430px) {

  .coursesHolderMobileCartView .courseCardDiv,
  .coursesHolderMobileCartView .courseCardHolerDiv {
    width: 20% !important;
  }
}

@media (min-width: 768px) {
  .coursesHolderMobileCartView {
    -ms-overflow-style: thin !important;
    scrollbar-width: thin !important;
  }

  .coursesHolderMobileCartView::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border: 3px solid gray;
  }
}