.cartView .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px 0px 0px;
	margin-bottom: 15px;
}

.cartView .header p {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 0px;
}

.cartView .header button {
	background: transparent;
	border: 0px;
	color: #d2d2d2;
	font-size: 22px;
	font-weight: 600;
	height: fit-content;
}

.cartView .actionBtn {
	border-radius: 5px;
	width: 100%;
	background: #1573fe;
	border: 0px;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: -0.15px;
	padding: 6px 10px;
}

.cartView .actionBtn img {
	width: 16px;
	margin-left: 8px;
}

.cartView .disableActionBtn {
	background: #888585;
	cursor: not-allowed;
}

@media (max-width: 568px) {
	.cartView .header button {
		display: none;
	}
}

/* START : style for desktop */
@media (min-width: 768px) {
	.cartView .header {
		display: none;
	}
	.cartView .actionBtn {
		position: fixed;
		bottom: 10px;
		width: 370px;
	}
}
/* END : style for desktop */
