.userDetailsView .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px 10px 0px;
}

.userDetailsView .header p {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 0px;
}

.userDetailsView .header button {
	background: transparent;
	border: 0px;
	color: #d2d2d2;
	font-size: 22px;
	font-weight: 600;
	height: fit-content;
}

.userDetailsView .content .note {
	font-size: 13px;
}

.userDetailsView .content .inputCotainer input {
	border: 1px solid #e9e9e9;
	border-radius: 5px;
}

.userDetailsView .actionBtn {
	border-radius: 5px;
	width: 100%;
	background: #1573fe;
	border: 0px;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: -0.15px;
	padding: 6px 10px;
	margin-top: 10px;
}

.userDetailsView .actionBtn img {
	width: 16px;
	margin-left: 8px;
}

.userDetailsView .content .error {
	margin-bottom: 6px;
	font-size: 13px;
	font-weight: 500;
	color: darkred;
}

@media (max-width: 768px) {
	.userDetailsView .header button {
		display: none;
	}
}

/* START : style for desktop */
@media (min-width: 768px) {
	.userDetailsView .header {
		display: none;
	}
	.userDetailsView .actionBtn {
		position: fixed;
		bottom: 10px;
		width: 370px;
	}
}
/* END : style for desktop */
