.transactionsView .pdfIcon{
    width: 26px;
    cursor: pointer;
}


.transactionsView .tdVendor span,
.transactionsView .tdParticular span{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.transactionsView .tdDate .d1{
    margin-right: 7px;
}
.transactionsView .tdDate .d1,
.transactionsView .tdDate .d2{
    background-color: #FF9533;
    color: white;
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 14px;
}

.transactionsView .thParticular,
.transactionsView .tdParticular{
    width: 235px;
}
.transactionsView .thCost,
.transactionsView .tdCost{
    width: 135px;
}
.transactionsView .thGateway,
.transactionsView .tdGateway{
    width: 128px;
}
.transactionsView .thVendor,
.transactionsView .tdVendor{
    width: 228px;
}
.transactionsView .thDate,
.transactionsView .tdDate{
    width: 219px;
}
.transactionsView .thInvoice,
.transactionsView .tdInvoice{
    width: 99px;
}


.transactionsView tbody{
    font-size: 15px;
}

/* START : Style as per the classes got from inspect element */
table{
    border-collapse: separate;
    border-spacing: 0;
}

/* .table-striped>tbody>tr:nth-of-type(odd){
    background-color: red;
} */

.table-striped>tbody>tr:nth-of-type(odd) td{
    border-bottom: 2px solid #E5E5E5;
    border-top: 2px solid #E5E5E5;
}
table tr:first-child th{
    border-top: 2px solid #E5E5E5;
    /* border-bottom: 1px solid #B1B1B1; */
}
table tr:first-child th:first-child {
    border-top-left-radius: 10px;
}
table tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

table tr:last-child td{
    border-bottom: 2px solid #E5E5E5;
}
table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.table>:not(caption)>*>*{
    padding: .5rem 20px;
}
/* END : Style as per the classes got from inspect element */


@media (max-width: 1250px) {
    .transactionsView .tdDate .d1,
    .transactionsView .tdDate .d2{
        display: block;
        width: fit-content;
        margin-bottom: 7px;
    }
}

@media (max-width: 1100px) {
    .transactionsView .thParticular, .transactionsView .tdParticular {
        width: 255px;
    }
    .transactionsView .thVendor, .transactionsView .tdVendor {
        width: 248px;
    }
    .transactionsView .thDate, .transactionsView .tdDate {
        width: 299px;
    }
}

@media (max-width: 900px) {
    .transactionsView tbody{
        font-size: 14px;
    }    
    .transactionsView .tdDate .d1,
    .transactionsView .tdDate .d2{
        font-size: 13px;
    }
    .transactionsView .thParticular, .transactionsView .tdParticular {
        width: 285px;
    }
    .transactionsView .thVendor, .transactionsView .tdVendor {
        width: 278px;
    }
    .transactionsView .thDate, .transactionsView .tdDate {
        width: 950px;
    }
    .transactionsView .thInvoice, .transactionsView .tdInvoice {
        width: 0px;
    }
}

@media (max-width: 680px) {
    .transactionsView table{
        width: 800px;
    }    
    .transactionsView .formContainer{
        overflow-x: scroll;
    }    
    .transactionsView .thParticular,
    .transactionsView .thCost,
    .transactionsView .thGateway,
    .transactionsView .thVendor,
    .transactionsView .thDate,
    .transactionsView .thInvoice{
        font-size: 15px;
    }
    .transactionsView .thParticular, .transactionsView .tdParticular {
        width: 285px;
    }
    .transactionsView .thDate, .transactionsView .tdDate {
        width: 250px;
    }
}