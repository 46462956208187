.exploreMobileView .exploreContentContainer {
	border: 1px solid #c9c9c9;
	padding: 10px 15px 0px 15px;
	border-radius: 10px;
	margin-bottom: 30px;
}

.exploreMobileView .exploreContentContainer .title {
	font-weight: 700;
	font-size: 20px;
}

.exploreMobileView .exploreContentContainer .description {
	color: #7f7f7f;
}

.exploreMobileView .exploreContentContainer .pointerContainer {
	margin-bottom: 8.5px;
	color: #252525;
}

.exploreMobileView .exploreContentContainer .pointerContainer img {
	width: 20px;
	margin-right: 10px;
}

.exploreMobileView .exploreContentContainer .viewCoursesCategory {
	background-color: #252525;
	color: white;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	padding: 8px;
	width: 100%;
	display: block;
	border-radius: 5px;
	text-align: center;
	margin-bottom: 15px;
}

.exploreMobileView .exploreContentContainer .viewCoursesCategory img {
	width: 15px;
	margin-left: 10px;
}

@media (max-width: 568px) {
	.exploreMobileView .exploreContentContainer .pointerContainer {
		font-size: 15px;
	}
	.exploreMobileView .exploreContentContainer .description {
		font-size: 14px;
	}
}
