.mainCheckoutContainer {
    margin: 2rem 10vw;
    max-width: 1340px;
    text-align: left;
}

.mainCheckoutContainer .adContainer{
    background-color: #1D3762;
    color: white;
}

.mainCheckoutContainer .adContainer .container1{
    justify-content: space-between;
    align-items: center;
    padding-left: 3vw;
}

.mainCheckoutContainer .adContainer .container1 .adDescriptionContainer{
    margin: 10px 0px;
}

.mainCheckoutContainer .adContainer .container1 .adDescriptionContainer .p1 {
    margin-bottom: 0px;
    font-size: 1rem;
}

.mainCheckoutContainer .adContainer .container1 .adDescriptionContainer .p2 {
    margin-bottom: 0px;
    font-size: 1.1rem;
    font-weight: 700;
}

.mainCheckoutContainer .adContainer .container1 .adPriceContainer p{
    margin-bottom: 5px;
}

.mainCheckoutContainer .adContainer .container1 .adPriceContainer button{
    background: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    color: #1D3762;
    font-weight: 400;
    padding: 0.1vw 3vw;
}


.mainCheckoutContainer .innerMainCheckoutContainer{
    margin: 70px 10vw;
}

.mainCheckoutContainer .innerMainCheckoutContainer .discountCouponContainer input{
    padding: 10px;
    border: 1px solid #767373;
    color: #767373;
    border-radius: 6px;
}

.mainCheckoutContainer .innerMainCheckoutContainer .discountCouponContainer button{
    height: fit-content;
}

.mainCheckoutContainer .innerMainCheckoutContainer .amountContainer p{
    margin-bottom: 2px;    
}

.mainCheckoutContainer .paymentGatway div{
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
}

.mainCheckoutContainer .paymentGatway div input{
    margin-right: 10px;
}

.mainCheckoutContainer .innerMainCheckoutContainer .amountContainer #checkOutBtn{
    background: #252525;
    border-radius: 4px;
    color: white;
    border: 0px;
    padding: 6px 85px;
    margin-top: 10px;
}

.mainCheckoutContainer .innerMainCheckoutContainer .discountCouponContainer .couponImg {
    margin-right: 20px;
    cursor: pointer;
}


@media (min-width: 1500px) {
    .mainCheckoutContainer .adContainer .container1{
        justify-content: center;
    }
}

@media (max-width: 1340px) {
    .mainCheckoutContainer{
        margin: 2rem;
    }
}


@media (max-width: 768px) {
    .mainCheckoutContainer {
        margin: 0rem 0vw;
    }
    
    .mainCheckoutContainer .adContainer .container1 .adDescriptionContainer{
        margin: 15px 0px;
    }

    .mainCheckoutContainer .innerMainCheckoutContainer{
        margin: 40px 50px;
    }
}

@media (max-width: 576px){
    .mainCheckoutContainer .adContainer{
        padding-top: 15px;
    }

    .mainCheckoutContainer .adContainer .container1 .adPriceContainer{
        max-width: 240px;
    }

    .mainCheckoutContainer .adContainer .container1 .adPriceContainer p{
        font-size: 1.1rem;
    }

    .mainCheckoutContainer .adContainer .container1 .adDescriptionContainer .p1,
    .mainCheckoutContainer .adContainer .container1 .adDescriptionContainer .p2{
        font-size: 1.5rem;
    }

    .mainCheckoutContainer .adContainer .container1 .adPriceContainer button{
        padding: 0.1vw 10vw;
    }

    .mainCheckoutContainer .innerMainCheckoutContainer{
        margin: 40px 10px;
    }

}

