.claimCertificateAgreePopupMainContainer .modal-content {
    border-radius: 10px;
    text-align: center;
    background: #f4f4f4;
}

.claimCertificateAgreePopupMainContainer .modal-header {
    border: 0px;
    justify-content: center;
    padding-bottom: 0px;
}

.claimCertificateAgreePopupMainContainer .modal-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3499999940395355px;
}

.claimCertificateAgreePopupMainContainer .modal-body {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.3499999940395355px;
}


.claimCertificateAgreePopupMainContainer .modal-footer {
    border: 0px;
    justify-content: center;
}


.claimCertificateAgreePopupMainContainer .modal-footer .actionBtns {
    background: #FFFFFF;
    color: #252525;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.44999998807907104px;
    border-radius: 10px;
    border: 0px;
    padding-top: 3px;
    padding-bottom: 3px;
}



@media (max-width: 568px) {
    .claimCertificateAgreePopupMainContainer .modal-dialog {
        align-items: flex-end;
        margin: 1rem 0rem 0rem 0rem;
    }

    .claimCertificateAgreePopupMainContainer .modal-content {
        border-radius: 10px 10px 0px 0px;
    }
}