.videoDiv {
	width: 90%;
	position: relative;
	padding-bottom: 56.25%;
}

.previewVideoIframe {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

@media (max-width: 992px) {
	.videoDiv {
		width: 100%;
	}
}
