.seriesContainerLoaderMobile{
    display: none;
}    
@media (min-width: 576px) {
    .seriesContainer{
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (max-width: 576px) {
    .seriesContainerLoaderDesktop{
        display: none;
    }
    .seriesContainerLoaderMobile{
        display: block;
    }    
}