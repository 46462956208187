.examContainer {
    margin-top: 5px;
}

/* START : Exam Card Helper */
.examContainerC{
    width: 98%;
}
.examContainerC .examCard{
    border: 0.5px solid #EDEDED;
    z-index: 99;
    background: #F8F8F8;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    /* background: red; */
}

.examContainerC .examCard .examInnerContainer{
    background: #F8F8F8;
}

@media (min-width: 1200px) {
    .examContainerC .examCard .shineOnCourseCard{
        width: 20% !important;
    }
}
/* END : Exam Card Helper */


.examContainer .examInnerContainer {
    background: white;
    padding: 6px 10px;
    border-radius: 0px 0px 10px 10px;
}

.examContainer .examInnerContainer hr {
    margin: 10px 0px;
}


.examContainer .lockExamMainContainer {
    cursor: not-allowed;
}



.examContainer .examInnerContainer .lockExamContainer,
.examContainer .examInnerContainer .examCompletedContainer {
    font-size: 0.8rem;
    display: flex;
}

.examContainer .examInnerContainer .examCompletedContainer {
    align-items: center;
    background: #F1FFE7;
    padding: 6px;
    border-radius: 5px;
    margin-bottom: 8px;
}

.examContainer .examInnerContainer .examCompletedContainer i {
    height: fit-content;
    color: #55DC47;
    padding: 3.4px 3.4px 3.3px 3.4px;
    border-radius: 20px;
    margin-right: 6px;
    border: 1px solid black;
    -webkit-text-stroke: 0.3px #000000;
}

.examContainer .examInnerContainer .lockExamContainer img {
    width: 11px;
    height: fit-content;
    margin-right: 6px;
}


.examContainer .examInnerContainer .viewResultExamBtn {
    background: #252525;
    width: 100%;
    color: white;
    border: 0px;
    border-radius: 6px;
    padding: 3px;
    margin-bottom: 8px;
    font-size: 14px;
}

.examContainer .examImageContainer {
    position: relative;
}

.examContainer .examImageContainer .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.75);
    border-radius: 12px;
}