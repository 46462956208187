.timelineMaincontainer .timelineContentContainer .courseCardView {
    padding: 12px;
    text-align: center;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px #0000001A;
}

.timelineMaincontainer .timelineContentContainer .courseCardView .xrow {
    align-items: center;
    --bs-gutter-x: 0px;
}

.timelineMaincontainer .timelineContentContainer .courseCardView .xrow .tagsComponent  {
    margin-right: 10px;
}

.timelineMaincontainer .timelineContentContainer .courseCardView .topHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timelineMaincontainer .timelineContentContainer .courseCardView .topHeading span {
    color: #252525;
    font-weight: 500;
}

.timelineMaincontainer .timelineContentContainer .courseCardView .topHeading img {
    width: 18px;
}

.timelineMaincontainer .timelineContentContainer .courseCardView .courseTitle {
    color: #5B5B5B;
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
}


@media (max-width: 768px) {
    .timelineMaincontainer .timelineContentContainer .courseCardView {
        width: 100%;
    }
    .timelineMaincontainer .timelineContentContainer .courseCardView .xrow {
        --bs-gutter-x: 1.5rem;
    }    
}

@media (max-width: 576px) {
    .timelineMaincontainer .timelineContentContainer .courseCardView {
        border-radius: 0px;
    }
    .timelineMaincontainer .timelineContentContainer .courseCardView .xrow {
        --bs-gutter-x: 1rem;
    }

    .timelineMaincontainer .timelineContentContainer .courseCardView .courseTitle {
        font-size: 14px;
    }
}
