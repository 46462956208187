#aboutUsBanner img {
    width: 100%;
}

#aboutUsBannerTextDivDektop,
#aboutUsBannerTextDivTablet {
    left: 5% !important;
    top: 20% !important;
    max-width: 43%;
}

#aboutUsBannerTextDivDektop h3,
#aboutUsBannerTextDivTablet h3 {
    background: linear-gradient(90deg, #1D3661 0%, #2F68CB 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

#aboutUsBannerTextDivDektop div,
#aboutUsBannerTextDivTablet div {
    color: white;
    /* background: #3565B8; */
    background: linear-gradient(90deg, #1D3661 0%, #2F68CB 99.15%);
    border-radius: 5px;
    width: 70%;
    padding: 11px 2rem 11px 11px;
    margin-top: 5%;
}

#aboutUsBanner .bottomNote{
    text-align: center;
    background: #252525;
    /* background: #1D3661; */
    color: white;
    padding: 16px 10px;
}

#aboutUsBanner .bottomNote span{
    font-size: 1rem;
}

@media (max-width: 1150px) {
    #aboutUsBannerTextDivDektop div {
        width: 80%;
        padding: 11px 11px 11px 11px;
    }

    #aboutUsBannerTextDivDektop h3 {
        font-size: 1.5rem;
    }
}

#aboutUsBannerTextDivDektop div p,
#aboutUsBannerTextDivTablet div p {
    margin-bottom: 0px;
    font-size: 1rem;
}


.aboutUsePageMainContainer{
    margin: 0px 10vw;
    max-width: 1340px;
    text-align: left;
}


.aboutUsePageMainContainer .reasonsToStartContainer{
    margin: 40px 0px;
}

.aboutUsePageMainContainer .reasonsToStartContainer .singleReason img{
    width: 115px;
    margin-bottom: 24px;
}

.aboutUsePageMainContainer .reasonsToStartContainer .singleReason .heading{
    font-weight: 600;
    margin-bottom: 19px;
}


.aboutUsePageMainContainer .whatIseMediSkillContainer{
    background: #313131;
}

.aboutUsePageMainContainer .whatIseMediSkillContainer .whatIseMediSkillInnerContainer{
    padding: 50px 10px;
    align-items :center;
}

.aboutUsePageMainContainer .whatIseMediSkillContainer .imgContainer{
    text-align: center;
}

.aboutUsePageMainContainer .whatIseMediSkillContainer .imgContainer img{
    width: 390px;
}

.aboutUsePageMainContainer .whatIseMediSkillContainer .textContainer {
    color: white;
}

.aboutUsePageMainContainer .whatIseMediSkillContainer .textContainer h1{
    font-size: 3rem;
    font-weight: 600;
}

.aboutUsePageMainContainer .careerContainer .careerInnerContainer{
    align-items :center;
}


.aboutUsePageMainContainer .careerContainer .imgContainer img{
    width: 100%;
}
.aboutUsePageMainContainer .careerContainer .textContainer h4{
    margin-bottom: 20px;
}


@media (max-width: 1340px) {
    .aboutUsePageMainContainer {
        margin: 2rem 0px 0px 0px;
    }
}


@media (max-width: 1200px) {
    .aboutUsePageMainContainer .whatIseMediSkillContainer .imgContainer img{
        width: 300px;
    }    
}

@media (max-width: 992px) {
    #aboutUsBanner img {
        content: url('../../assets/aboutUs/tabBanner.png')
    }

    #aboutUsBannerTextDivTablet {
        max-width: 40%;
    }

    #aboutUsBannerTextDivTablet div {
        width: 85%;
        padding: 11px 11px 11px 11px;
    }

    #aboutUsBannerTextDivTablet h3 {
        background: linear-gradient(90deg, #1D3661 0%, #2F68CB 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
    }

    .aboutUsePageMainContainer .whatIseMediSkillContainer .textContainer h1{
        font-size: 2.2rem;
    }    

    #aboutUsBannerTextDivTablet{
        left: 2% !important;
    }

}

@media (max-width: 768px) {
    .requestACallMainContainer{
        margin: 3rem 0px;
    }

    #aboutUsBannerTextDivTablet h3{
        font-size: 1.2rem;
    }

    .aboutUsePageMainContainer .whatIseMediSkillContainer .imgContainer img{
        width: 250px
    }    


    #aboutUsBannerTextDivTablet div{
        padding: 6px 10px;
    }
    #aboutUsBannerTextDivTablet div p{
        font-size: 0.8rem;
    }

    .aboutUsePageMainContainer .careerContainer .textContainer h4{
        font-size: 17px;
        margin-bottom: 5px;
    }

    .aboutUsePageMainContainer .careerContainer .textContainer p{
        font-size: 15px;
    }
}


@media (max-width: 576px) {
    #aboutUsBanner img {
        content: url('../../assets/aboutUs/mobileBanner.png')
    }

    #aboutUsBannerTextDivTablet {
        max-width: 100%;
        width: 100%;
        left: unset !important;
        padding: 0px 20px;
        top: 8% !important;
    }

    #aboutUsBannerTextDivTablet{
        text-align: center;
    }

    #aboutUsBannerTextDivTablet div{
        width: 100%;
    }

    #aboutUsBannerTextDivTablet h3 {
        font-size: 1.5rem;
    }

    .aboutUsePageMainContainer .whatIseMediSkillContainer .imgContainer img{
        width: 313px
    }    

    .aboutUsePageMainContainer .whatIseMediSkillContainer .textContainer{
        margin-top: 40px;
    }

    #aboutUsBannerTextDivTablet div p{
        font-size: 1rem;
    }

    .aboutUsePageMainContainer .careerContainer .careerInnerContainer{
        height: 50vh;
    }
}

