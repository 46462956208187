.mobileQtySpeedCCUiContainer label{
    font-size: 0.9rem;
}


.mobileQtySpeedCCUiContainer button{
    float: right;
    background: transparent;
    border: 0px;
    font-size: 0.9rem;
    border: 1px solid #c3bfbf;
    border-radius: 9px;
    padding: 2px 15px;
}