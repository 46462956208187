.mainCourseDetailsPageContainer {
    /* margin: 2rem 10vw; */
    /* max-width: 1340px; */
    margin: 2rem 8vw;
    max-width: 1900px;
    text-align: left;
}

.mainCourseDetailsPageContainer .playListMainContainer {
    border: 0.5px solid #b8b8b8;
    border-radius: 7px;
    padding: 10px;
    /* height: fit-content; */
    overflow: auto;
    /* overflow-y: scroll;  */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mainCourseDetailsPageContainer .playListMainContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mainCourseDetailsPageContainer .playListMainContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scroll bar ref */
/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_hide_scrollbar_keep_func */


#playListMainContainer {
    position: fixed;
    top: 100px;
    display: none;
}


#playListMainContainerFIXED {
    position: absolute;
    visibility: hidden;
    display: none;
}


@media (max-width: 1340px) {
    .mainCourseDetailsPageContainer {
        margin: 2rem;
    }
}



@media (max-width: 992px) {
    #mainCourseDetailsPageContainer {
        margin: 2rem 0px;
    }

    #r1 {
        margin: 0px;
    }

    #videoPlayerDiscriptionContainer {
        padding: 0px;
    }

    .videoInnerContainer {
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
    }

    .videoInfo {
        margin-right: calc(var(--bs-gutter-x) * .5);
        margin-left: calc(var(--bs-gutter-x) * .5);
    }

    #r2 {
        margin: 10px 1rem;
        max-width: 100%;
    }

    #outsideCommentsContainer {
        max-width: 100%;
        margin: 0px 1rem;
    }
}


@media (max-width: 768px) {
    .mainCourseDetailsPageContainer {
        margin: 2rem 20px;
    }
}



.helpLoaderForVideoPlayer {
    visibility: hidden !important;
    height: 0px !important;
}

.card {
    border-radius: 10px;
}
