.displayNameMainConatainer .error{
    margin-bottom: 12px;
    margin-left: 4px;
}

.displayNameMainConatainer .inputConatiner .input {
    width: 100%;
}

.displayNameMainConatainer .nextBtn{
    margin-top: 150px;
}

@media (max-width: 568px) {
    .displayNameMainConatainer .nextBtn{
        margin-top: 80px;
    }    
}

