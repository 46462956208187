.desktopTabCardView .cardDetails {
    background: white;
    padding: 8px 15px 16px 15px;
    box-shadow: 0px 4px 26px 0px #8BA7AD40;
    border-radius: 0px 0px 10px 10px;
}

.desktopTabCardView .cardDetails p {
    font-size: 15px;
    /* margin-bottom: 9px; */
}


.desktopTabCardView .cardDetails .durationContainer {
    padding: unset;
    font-weight: 700;
    margin-bottom: 17px;
}

.desktopTabCardView .cardDetails .durationContainer img {
    width: 14px;
    margin-right: 10px;
}


.desktopTabCardView .cardDetails .cardDescription {
    font-weight: 400;
}


.desktopTabCardView .cardDetails .cardPointers ul {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
}

.desktopTabCardView .cardDetails .cardPointers ul li {
    margin-bottom: 5px;
}

.desktopTabCardView .cardDetails .cardPointers ul li::before {
    content: "";
    display: inline-block;
    margin-right: 10px;
    margin-top: -3px;
    width: 20px;
}

.desktopTabCardView .cardDetails .cardIcons img {
    width: 60%;
    margin-bottom: 7px;
}

.desktopTabCardView .cardDetails .cardIcons span {
    font-size: 12px;
    font-weight: 400;
}


.desktopTabCardView .cardDetails .dealEndingContainer {
    color: #FF0000;
    display: flex;
    align-items: center;
}

.desktopTabCardView .cardDetails .dealEndingContainer img {
    width: 14px;
    margin-right: 8px;
}



.desktopTabCardView .cardDetails .priceContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.desktopTabCardView .cardDetails .priceContainer p{
    margin-bottom: 5px;
}
.desktopTabCardView .cardDetails .priceContainer .alwaysDisplayPrice {
    font-size: 25px;
    font-weight: 700;
}

.desktopTabCardView .cardDetails .priceContainer .crossPrice {
    font-size: 18px;
    font-weight: 400;
    color : #8B8B8B;
    position: relative;
    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 46%;
        right: 0;
        color:#FF0000;
        border-top: 1px solid;
        border-color: inherit;
        -webkit-transform: skewY(-22deg);
        -moz-transform: skewY(-22deg);
        transform: skewY(-22deg);
    }

}

.desktopTabCardView .cardDetails .priceContainer .discountedPercentage {
    font-size: 18px;
    font-weight: 700;
    color:#FF0000;
}




.desktopTabCardView .cardDetails .discountTextContainer {
    color: #B4B4B4;
    font-size: 12px;
    cursor: pointer;
}

.desktopTabCardView .cardDetails .discountTextContainer img {
    width: 15px;
    margin-right: 8px;
}


.desktopTabCardView .cardDetails .btnContainer{
    display: flex;
    justify-content: space-around;
}
.desktopTabCardView .cardDetails .btnContainer .col-my5{
    width: 45%;
}

.desktopTabCardView .cardDetails .btnContainer .enrollNowBtn,
.desktopTabCardView .cardDetails .btnContainer .contactUsBtn{
    background-color: #FFD814;
    border-radius: 6px;
    color: #1573FE;
    font-size: 16px;
    font-weight: 700;
    border: 0px;
    padding: 6px 0px;
}
.desktopTabCardView .cardDetails .btnContainer .enrollNowBtn a{
    text-decoration: none;
}
.desktopTabCardView .cardDetails .btnContainer .cartActionBtn{
    background-color: white;
    border: 0.5px solid #D1D1D1;
    font-size: 16px;
    font-weight: 700;
    padding: 6px 0px;
    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px #0000000F;
}

.desktopTabCardView .cardDetails .btnContainer .cartActionBtn[disabled],
.desktopTabCardView .cardDetails .btnContainer .enrollNowBtn[disabled] {
    cursor: not-allowed;
}

.desktopTabCardView .courseThumbnail{
    border-radius: 10px 10px 0px 0px;
}

@media (max-width: 768px) {
    .desktopTabCardView .courseThumbnail{
        display: none;
    }

    .desktopTabCardView .cardDetails .cardIcons img {
        width: 40%;
    }

    .desktopTabCardView .cardDetails .btnContainer{
        z-index: 1;
        position: relative;
    }
} 

