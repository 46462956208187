.examProgressBarContainer {
    position: relative;
}

.examProgressBarContainer .steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 260px;
}

.examProgressBarContainer .step {
    width: 16px;
    height: 16px;
    background: #fff;
    border: 2px solid white;
    border-radius: 50%;
    transition: background 1s;

    z-index: 999;
}

.examProgressBarContainer .step.selected {
    border: 2px solid #4B81BD;
}


.examProgressBarContainer .step.completed {
    border: 2px solid white;
    background: red;
}

.examProgressBarContainer .progressContainer {
    position: absolute;
    width: 100%;
    border-bottom: 2px solid white;
    z-index: 999;

    height: 4px;
    margin-top: 6px;
}

.examProgressBarContainer .percent {
    position: absolute;
    border: 2px solid red;
    background: red;
    z-index: 1;
    transition: width 1s;
    margin-top: 1px;
    margin-left: 1.8px;
}


@media (max-width: 992px) {
    .examProgressBarContainer .steps {
        width: 180px;
    }
}