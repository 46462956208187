#centerTagConatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    font-size: 18px;
    color: #252525;
    flex-direction: column;
}


#email,
#password {
    box-sizing: border-box;
    border: 1px solid #252525;
    border-radius: 15px 0px 0px 15px;
    padding: 20px;
    margin-bottom: 15px;
    border-width: 1px 0px 1px 1px;
}

input:read-only#email,
input:read-only#password {
    background: white;
    cursor: not-allowed;
}

#emailIcon,
#passwordIcon {
    width: 70px;
    background: transparent;
    box-sizing: border-box;
    border: 1px solid #252525;
    border-radius: 0px 15px 15px 0px;
    padding: 20px;
    margin-bottom: 15px;
    border-width: 1px 1px 1px 0px;
}

#centerTagConatiner #countryCode {
    background: white;
    border-right: 0px;
    border-radius: 10px 0px 0px 10px !important;
    padding: 20px 15px;
    border: 1px solid;
}

#centerTagConatiner #phoneNumber {
    border-left: 0px;
    padding: 20px 0px;
}


#loginSubmit,
#otpSubmit,
#signupSubmit,
#createPassword,
#confirmationBtn1,
#confirmationBtn2 {
    height: 49px;
    background: #252525;
    color: white;
    border-radius: 15px;
    border: 0px;
    margin-bottom: 15px;
}

#loginSubmit:hover,
#signupSubmit:hover,
#otpSubmit:hover,
#createPassword:hover,
#confirmationBtn1:hover,
#confirmationBtn2:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 2px 4px rgba(0, 0, 0, 0.2);
    background: #363636;
}

#loginSubmit:focus,
#signupSubmit:focus,
#otpSubmit:focus,
#createPassword:focus,
#confirmationBtn1:focus,
#confirmationBtn2:focus {
    transform: translateY(2px);
}

.loginSignupPageLoadingBtn {
    background: #39465c !important;
    cursor: pointer;
    opacity: 0.9;
}

.loginSignupPageLoadingBtn:hover {
    cursor: default !important;
}


#countryCodeContainer select:focus,
#phoneNumber:focus {
    box-shadow: none;
    border-color: #212529;
}


@media screen and (max-width: 1400px) {
    .centerTagConatinerSingUp {
        margin-top: 150px;
    }
}

@media screen and (max-width: 768px) {

    #emailIcon,
    #passwordIcon,
    #email,
    #password {
        height: 62px;
    }

    #emailIcon,
    #passwordIcon {
        width: 62px;
    }
}

.otp {
    width: 59px;
    height: 62px;
    border: 1px solid #252525;
    border-radius: 15px;
    margin-right: 12px;
    font-size: 35px;
    margin-bottom: 15px;
}

@media screen and (max-width: 458px) {
    .otp {
        width: 43px;
        height: 46px;
        font-size: 24px;
    }
}

@media screen and (max-width: 360px) {
    .otp {
        width: 34px;
        height: 38px;
        font-size: 16px;
        margin-right: 8px;
    }
}

#forgotPassword {
    font-size: 18px;
    color: #252525;
    margin-bottom: 0px;
}

#centerTagConatiner a {
    color: #1D3661;
    text-decoration: none;
    font-weight: 400;
}

#centerTagConatiner a:hover {
    color: #305087;
}


#centerTagConatiner hr {
    opacity: 1;
    color: black;
    margin: 10px 0px;
}


.signUpInputBoxes {
    box-sizing: border-box;
    border: 1px solid #252525;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 15px;

}

.alert {
    text-align: left;
    padding: 8px;
    font-size: 14px;
}

/* hide default arrow in input type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}