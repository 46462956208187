/* html,
body,
#root {
    height: 100%;
    margin: 0;
} */

.cartFiller {
    min-height: 100%;
}

.mainCart,
#cartPageRecentlyViewedProducts {
    background: #EAEDED;
    margin: 2rem 10vw;
    padding-bottom: 1rem;
    max-width: 1340px;
    text-align: left;
}

.mainCart {
    padding-top: 2rem;
}

.mainCart #allCartItemsContainer {
    background: white;
    border-radius: 10px;
}

.mainCart #cartSubTotalContainer {
    background: white;
    border-radius: 10px;
    height: fit-content;
    padding: 15px;
    text-align: center;
}

.mainCart #allCartItemsContainer #priceHeading {
    color: #252525;
    font-weight: 400 !important;
}

.cartItem {
    margin-bottom: 15px;
}

#allCartItemsContainer .cartItem .courseImgContainer {
    padding-right: 0px;
    cursor: pointer;
}

#allCartItemsContainer .cartItem .courseHeading {
    cursor: pointer;
}

#allCartItemsContainer .cartItem .courseHeading,
#allCartItemsContainer .cartItem .coursePrice {
    font-size: 1.1rem;
}

#allCartItemsContainer .cartItem .courseDescription {
    font-size: 0.9rem;
    margin-bottom: 5px;
}

#allCartItemsContainer .cartItem .cartItemButton {
    color: #007285;
    background: transparent;
    border: 0px;
    padding-left: 0px;
    padding-right: 12px;
}

#allCartItemsContainer .cartItem .cartItemButton:hover {
    color: #033c46;
}

#allCartItemsContainer .cartItem .cartItemButtonContainerMobile {
    margin-top: 10px;
    text-align: center;
}

#allCartItemsContainer .cartItem .cartItemButtonContainerMobile .cartItemButton {
    background: #D9D9D9;
    border-radius: 3px;
    padding: 3px;
    font-size: 0.9rem;
}


#allCartItemsContainer .cartItem .coursePriceContainer {
    text-align: right;
}

.mainCart #cartSubTotalContainer button {
    background: #252525;
    border-radius: 5px;
    color: white;
}


.mainCart #cartTnC {
    padding-left: 3%;
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 30px;
}

#cartPageRecentlyViewedProducts {
    background-color: white;
}

#cartPageRecentlyViewedProducts div::-webkit-scrollbar {
    display: none;
}


#cartPageRecentlyViewedProducts .viewedProduct {
    margin-right: 20px;
    cursor: pointer;
}


.shareIcon {
    max-width: 60px;
    border: 1px solid;
    padding: 4px;
    border-radius: 50px;
    margin: 0px 10px;
    cursor: pointer;
}

.textCopiedAlter {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.textCopiedAlter.visible {
    display: block;
    opacity: 1;
    transition-delay: 0.5s;
}

#emptyCart {
    width: 50%;
}

/* .cartFiller{
    height: 100vh;
} */

@media (max-width: 1340px) {

    .mainCart,
    #cartPageRecentlyViewedProducts {
        margin: 2rem;
    }
}


@media (min-width: 1800px) {

    #allCartItemsContainer .cartItem .courseHeading,
    #allCartItemsContainer .cartItem .coursePrice {
        font-size: 1.3rem;
    }

    #allCartItemsContainer .cartItem .courseDescription {
        font-size: 1.1rem;
    }

    #allCartItemsContainer .cartItem .cartItemButton {
        font-size: 1.1rem;
    }

}

@media (max-width: 992px) {
    #allCartItemsContainer .cartItem .coursePrice {
        font-size: 0.9rem;
    }

    #allCartItemsContainer .cartItem .coursePriceContainer {
        padding-left: 0px;
    }
}

@media (max-width: 768px) {

    .mainCart {
        margin: 2rem 10px;
        padding: 2rem 25px 1rem 25px;
    }

    #cartPageRecentlyViewedProducts {
        margin: 2rem 10px;
    }

    #emptyCart {
        width: 100%;
    }

}

@media (max-width: 578px) {
    /* #allCartItemsContainer .cartItem .courseHeading {
        margin-bottom: 3px;
    } */

    #allCartItemsContainer .cartItem .coursePrice {
        line-height: 16px;
    }

    #allCartItemsContainer .cartItem .courseHeading,
    #allCartItemsContainer .cartItem .coursePrice {
        font-size: 1rem;
    }

    #allCartItemsContainer .cartItem .courseDescription {
        font-size: 0.8rem;
    }
}