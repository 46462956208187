.examCard {
    background-color: white;
    display: flex;
    align-items: center;
    width: 49%;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.examCardOddMargin {
    margin-left: 7.5px;
}

.examCardEvenMargin {
    margin-right: 7.5px;
}

.examCard .examTitle {
    color: #252525;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 1rem;
}

.examCard .examInnerContainer .pfHeading {
    font-size: 14px;
}

.examCard .examInnerContainer .pfHeading .passHeading {
    border-radius: 7px;
    border: 1px solid #C1EF72;
    padding: 5px;
    color: #517919;
}

.examCard .examInnerContainer .pfHeading .failHeading {
    border-radius: 7px;
    border: 1px solid #F7A8AD;
    padding: 5px;
    color: #BA131A;
}

.examCard .examInnerContainer .pfHeading .n1{
    display: block;
    margin-top: 8px;
}

.examCard .examInnerContainer .btnTxt {
    font-size: 15px;
}

.examCard .examInnerContainer .viewResultBtn {
    color: #1573FE;
    border-radius: 7px;
    border: 1px solid #1573FE;
    margin-right: 10px;
}

.examCard .examInnerContainer .reAttemptBtn {
    color: white;
    border-radius: 7px;
    border: 1px solid #252525;
    background-color: #252525;

}

@media (max-width: 1200px) {
    .examCard .examTitle {
        margin-bottom: 1rem;
    }
}

@media (max-width: 1000px) {
    .examCard .examInnerContainer .pfHeading,
    .examCard .examInnerContainer .btnTxt{
        font-size: 13px;
    }
}

@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .examCard .examTitle {
        font-weight: 500;
        font-size: 13px !important;
    }
}

@media (max-width: 992px) {
    .examCard {
        width: 48%;
    }
}

@media (max-width: 768px) {
    .examCard {
        width: 100%;
    }

    .examCardOddMargin {
        margin-left: 0px;
    }

    .examCardEvenMargin {
        margin-right: 0px;
    }
}


@media (max-width: 576px) {
    .examCard .examInnerContainer .pfHeading,
    .examCard .examInnerContainer .btnTxt{
        font-size: 12px;
    }
}


@media (max-width: 550px) {
    .examCard .examTitle {
        font-size: 15px;
    }
}

@media (max-width: 450px) {
    .examCard .examTitle {
        font-size: 13px;
    }
    .examCard .examTitle {
        margin-bottom: 0.5rem;
    }
}