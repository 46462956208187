.registrationMainContainer {
    border-radius: 10px;
    background-color: white;
    border: 1px solid #F2F2F2;
    padding: 11px;
    position: relative;
    z-index: 1;
    /* background-color: lightgreen; */
}

.registrationMainContainer input[type="text"]{
    border-radius: 5px;
    border: 1px solid #E9E9E9;
    padding: 6px 15px;
    color: #252525;
    font-size: 15px;
}

.registrationMainContainer .inputFullName{
    width: 100%;
    margin-bottom: 15px;
}

.registrationMainContainer .phoneNoContainer{
    margin-bottom: 15px;
}

.registrationMainContainer .tncContainer{
    font-size: 11px;
    min-height: fit-content;
    margin-bottom: 9px;
}

.registrationMainContainer .tncContainer label{
    width: 96%;
}

.registrationMainContainer .imIntrestedBtn{
    width: 100%;
    padding: 6px 0px;
    border: 0px;
    background-color: #1573ff;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.registrationMainContainer .imIntrestedBtn img{
    margin-left: 7px;
    width: 16px;
}



/* START : Style for Tag */
.tagsComponent{
    margin-left: 0px !important;
    /* margin-bottom: 5px !important; */
}
/* END : Style for Tag */
