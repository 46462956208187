.timelineMaincontainer .timelineContentContainer .supportCardView {
    padding: 12px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.10);
}

.timelineMaincontainer .timelineContentContainer .supportCardView a {
    color: unset;
    text-decoration: none;
}

.timelineMaincontainer .timelineContentContainer .supportCardView .topHeading {
    color: #252525;
    font-size: 15px;
    font-weight: 500;
}


.timelineMaincontainer .timelineContentContainer .supportCardView .r1 {
    align-items: flex-start;
    cursor: pointer;
}

.timelineMaincontainer .timelineContentContainer .supportCardView .r1 .qMark {
    width: 45px;
}

.timelineMaincontainer .timelineContentContainer .supportCardView .r2 .heading {
    color: #252525;
    font-size: 15px;
    font-weight: 500;
}

.timelineMaincontainer .timelineContentContainer .supportCardView .r2 .r2_1 {
    margin-bottom: 7px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.timelineMaincontainer .timelineContentContainer .supportCardView .r2 .r2_1 img {
    width: 8px;
    float: right;
    margin-right: 5px;
}

.timelineMaincontainer .timelineContentContainer .supportCardView .r2 .r2_1 span {
    color: #7C7E85;
    font-size: 13px;
    font-weight: 400;
}

@media (max-width: 768px) {
    .timelineMaincontainer .timelineContentContainer .supportCardView {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .timelineMaincontainer .timelineContentContainer .supportCardView {
        border-radius: 0px;
    }
}
