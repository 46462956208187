.payPageMainContainer {
    margin: 2rem 10vw;
    max-width: 1340px;
    text-align: left;
}

#modal{
    margin-top: 40px !important;
}

@media (max-width: 1340px) {
    .payPageMainContainer {
        margin: 2rem;
    }
}

@media (max-width: 768px) {
    .payPageMainContainer {
        margin: 2rem 20px;
    }
}
