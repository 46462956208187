footer{
    width: 100%;
    background-color: #252525;
    color: white;
    padding: 40px 0px !important;
    font-size: 18px;
}

.footerLogo{
    width: 25px;
    margin-top:-2px
}

.footerLogoTM{
    width: 50px;
    height: 26px;
}

.footerPTage{
    margin-bottom: 7px !important;
}

#desktopFooter{
    padding: 0px 20px;
}


#footer .socialMediaIcons{
    width: 22px;
    margin-right: 25px;
    text-align: center;
}

#footer .socialMediaIconsTM{
    width: 60px;
}

@media (max-width: 992px){
    #desktopFooter{
        max-width: 100% !important;
    }
}


#mobileFooter{
    text-align: center;
}

#emptySection{
    text-align: center;
    padding: 14px;
    background: #1D3661;;
    color: #C0C0C0;
}

.undoAnchorEffect{
    color: white;
    text-decoration: none;
}

.undoAnchorEffect:hover{
    color: white;
}

@media (max-width: 450px){
    #footer .socialMediaIconsTM{
        width: 50px;
    }    
}

@media (max-width: 390px){
    #footer .socialMediaIconsTM{
        width: 45px;
    }    
}