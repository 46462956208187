.callView .callBtn {
	border: 1px solid #1573fe;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 2px;
	border-radius: 10px;
	margin: 20px 0px;
	z-index: 1;
	position: relative;
}

.callView .callBtn img {
	margin-right: 30px;
	width: 22px;
}

.callView .callBtn p {
	margin-bottom: 0px;
}

@media (max-width: 768px) {
	.callView {
		display: none;
		margin-bottom: 20px;
	}
}
