.examPageBgHolder {
    background: #EBF4F8;
    padding: 3rem 0rem;
    height: 75vh;
}

.examPageMainContainer {
    margin: 0rem 20vw;
    max-width: 1340px;
    text-align: left;
}

.examPageHeadingContainer {
    padding: 1rem;
    background-color: white;
    border-top: 14px solid #4D80CD;
}

.examPageHeadingContainer .pointers {
    display: flex;
}

.examPageHeadingContainer .pointers p {
    margin-bottom: 10px;
}

.examPageHeadingContainer .pointers img {
    height: fit-content;
    margin-right: 8px;
    width: 20px;
}

.examPageMainContainer #startExamBtnContainer {
    text-align: center;
    margin-top: 25px;
}

.examPageMainContainer #startExamBtn {
    border: 0px;
    background-color: #4D80CD;
    border-radius: 10px;
    padding: 6px 120px;
    color: white;
}


.examPageMainContainer .questionContainer {
    padding: 1rem 1rem 0rem 1rem;
    background-color: white;
    border: 1px solid #252525;
    border-radius: 5px;
    margin-bottom: 25px;
}

.examPageMainContainer .questionContainer .question {
    margin-top: 10px;
    margin-bottom: 14px;
}

.examPageMainContainer .questionContainer .questionsThumbnailUrl{
    width: 40%;
    margin-bottom: 10px;
}

.examPageMainContainer .questionContainer .option {
    margin-bottom: 20px;
}

.examPageMainContainer .pageNavigationBtn {
    display: flex;
    justify-content: space-between;
}

.examPageMainContainer .pageNavigationBtn img {
    width: 25px;
    height: fit-content;
    cursor: pointer;
    height: 23px;
}

.examPageMainContainer .pageNavigationBtn .submitExamContainer {
    text-align: right;
}

.examPageMainContainer .pageNavigationBtn .submitExamContainer p {
    margin-bottom: 2px;
    color: red;
    font-size: 0.9rem;
}

.examPageMainContainer .pageNavigationBtn .submitExamContainer button {
    color: white;
    border: 0px;
    padding: 4px 80px;
    border-radius: 7px;
    background: #252525;
}



.examDurationContainer {
    position: sticky;
    top: 80px;
    border-radius: 5px;
    background: #4D80CD;
    color: white;
    margin: 0px 0px 15px 0px;
    align-items: center;
    z-index: 9999;
}

.examDurationContainer .duration{
    font-size: 30px;
}

.strikeContainer,
.durationContainer{
    padding: 10px;
}

.examDurationContainer .examStrikeNoteContainer {
    text-align: right;
    font-size: 10px;
    margin-top: 5px;
}

.durationContainer{
    border-left: 1px solid white;
}


/* .examPageMainContainer .pageNavigationBtn img{
    width: 20px;
    height: fit-content;
    cursor: pointer;
} */

#courseContentDescriptionContainer ul {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

#courseContentDescriptionContainer li {
    margin-bottom: 5px;
}

#courseContentDescriptionContainer li:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: -3px;
}




@media (max-width: 1340px) {
    .examPageMainContainer {
        margin: 0rem 5rem;
    }
}

@media (max-width: 992px) {
    .examPageMainContainer .questionContainer .questionsThumbnailUrl{
        width: 60%;
    }
}

@media (max-width: 768px) {
    .examPageMainContainer {
        margin: 0rem 1rem;
    }
    .examDurationContainer .duration{
        font-size: 1rem;
    }    
    .durationContainer{
        border-left: none;
    }
    .examPageMainContainer .questionContainer .questionsThumbnailUrl{
        width: 60%;
    }
}


@media (max-width: 568px) {
    .examPageMainContainer .questionContainer .questionsThumbnailUrl{
        width: 100%;
    }
}



