.ccAvenueMainContainer{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 400px;
    padding: 15px 10px;
    margin-top: 50px;
    border-radius: 10px;
}

.ccAvenueMainContainer .childContainer1 .blueBgWhiteRight{
    width: 50px;
    margin-bottom: 20px;
}
.ccAvenueMainContainer .childContainer1 .heading{
    color: #2e3a66;
}

.ccAvenueMainContainer .childContainer2{
    display: flex;
    justify-content: space-between;
    color: #7e7b7b;
    font-size: 15px;
}
