
.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .courseTitle {
    margin-bottom: 0px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #252525;
}

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .courseCard {
    margin-bottom: 27px;
    cursor: pointer;
}

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .timerContainer {
    align-items: center;
    display: flex;
    font-size: 0.8rem;
    margin: 6px 0px;
}

.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .timerContainer img {
    width: 20px;
    margin-right: 6px;
}


.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer {
    background: white;
    padding: 6px 10px;
    border-radius: 0px 0px 10px 10px;
}


.userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer hr {
    margin: 10px 0px;
}

.purchaseContainerLoaderMobile{
    display: none;
}

@media (max-width: 576px) {
    .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .courseCard {
        display: flex;
        align-items: center;
        background: white;
        padding: 8px;
        border-radius: 10px;
        margin-bottom: 16px;
    }

    .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .courseTitle {
        font-size: 14px;
    }

    .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .purchaseInnerContainer {
        padding: 6px 0px 6px 10px;
    }

    .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .timerContainer {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .timerContainer img {
        width: 15px;
    }

    .purchaseContainerLoaderDesktop{
        display: none;
    }
    .purchaseContainerLoaderMobile{
        display: block;
    }
    
}

@media (max-width: 400px) {
    .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .courseTitle {
        font-size: 13px;
    }

    .userDashboardMainContainer .myLearningMainContainer .purchaseContainer .courseCard .shineOnCourseCard {
        /* width: 80%; */
    }
}