.sampleCertificate .heading {
  color: #000000;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 23px;
}

.sampleCertificate .certificateHolder {
  position: relative;
}

.sampleCertificate .certificateImg {
  width: 75%;
  box-shadow: rgb(26 4 123 / 26%) 0px 10px 34px 0px,
    rgb(255 255 255 / 6%) 0px 0px 0px 1px;
}

.sampleCertificate .blurEffect {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  border: 2px solid #1a538a;
}

.sampleCertificate .viewCertificateBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border-radius: 5px;
  color: white;
  background-color: #252525;
  border: 0px;
  font-size: 14px;
  padding: 6px 12px;
}

.sampleCertificate .viewCertificateBtn img {
  margin-left: 7px;
  width: 16px;
}

@media (max-width: 768px) {
  .sampleCertificate .certificateImg {
    width: 80%;
  }

  .sampleCertificate .heading {
    font-size: 21px;
}
}


@media (max-width: 576px) {
  .sampleCertificate .certificateImg {
    width: 100%;
  }

  .sampleCertificate .heading {
    font-size: 19px;
}
.sampleCertificate .viewCertificateBtn {
    font-size: 13px;
  }

  .sampleCertificate .viewCertificateBtn img {
    width: 14px;
    margin-left: 5px;
  }
}

@media (max-width: 410px) {
  .sampleCertificate .viewCertificateBtn {
    width: 70%;
  }
}
