.playListContainer .subHeading{
    font-size: 14px;
    margin-bottom: 0px;
}

.playListContainer button{
    padding: 2px 0px 10px 0px;
    font-size: 1.1rem;
}

.playListContainer .accordion-button:not(.collapsed){
    color: #000000;
    box-shadow: unset;
    background-color: transparent;
}

.playListContainer .accordion-button:focus{
    box-shadow: unset;
}

.playListContainer .customAccordionCollapse .customAccordionCollapseInner{
    margin-bottom: 10px;
}

.playListContainer .customAccordionCollapseInner:hover{
    background: #F2F2F2;
}


.playListContainer .customAccordionCollapse .customAccordionCollapseInner .sideBarVideoThumbailContainer img{
    border-radius: 7px;
    cursor: pointer;
}

.playListContainer .customAccordionCollapse .customAccordionCollapseInner .videoText{
    padding-left: 0px;
    font-size: 14px;
    margin-bottom: 0px;
}

.playListContainer .customAccordionCollapse .customAccordionCollapseInner .videoText .videoTextSpan{
    cursor: pointer;
}

.playListContainer .customAccordionCollapse .customAccordionCollapseInner .videoText .heartIcon{
    cursor: pointer;
    width:16px;
    margin-right:6px;
}
.playListContainer .customAccordionCollapse .customAccordionCollapseInner .videoText .videoShareIcon{
    cursor: pointer;
}
