.enrolledTabMainContainer .heading {
    color: #252525;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-bottom: 9px;
}

.enrolledTabMainContainer .e_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.enrolledTabMainContainer .e_row span {
    color: #252525;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-bottom: 8px;
}

.enrolledTabMainContainer .pdfHolder {
    border-radius: 5px;
    padding: 8px;
    background: #F3F3F3;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
}

.enrolledTabMainContainer .pdfHolder .PDFIcon {
    width: 29px;
}

.enrolledTabMainContainer .pdfHolder .DownloadIcon {
    width: 22px;
}

.enrolledTabMainContainer .pdfHolder .div_01 {
    margin: 0px 20px 0px 10px;
}

.enrolledTabMainContainer .pdfHolder .div_01 p {
    margin-bottom: 0px;
}

.enrolledTabMainContainer .pdfHolder .div_01 .title {
    color: #324054;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.enrolledTabMainContainer .pdfHolder .div_01 .subTitle {
    color: #71839B;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    /* 145.455% */
    letter-spacing: -0.055px;
}

.enrolledTabMainContainer .pdfHolder .div_01 .subTitle span {
    color: #71839B !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    /* 145.455% */
    letter-spacing: -0.055px !important;
}


.enrolledTabMainContainer .e_row .payBtnContainer button {
    border-radius: 5px;
    border: 1px solid #C3C4C9;
    background: #FFF;
    color: #747474;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.39px;
    margin-left: 15px;
    padding: 4px 35px;
}


.enrolledTabMainContainer .e_row .installmentC span {
    display: block;
}

.enrolledTabMainContainer .e_row .installmentC .charges {
    color: #505050;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
}

.enrolledTabMainContainer .e_row .payBtnContainer button:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}



.enrolledTabMainContainer .e_row .payBtnContainer .amt {
    color: #858585;
}

@media (max-width: 576px) {
    .enrolledTabMainContainer .heading {
        font-size: 14px;
    }

    .enrolledTabMainContainer .e_row span {
        font-size: 14px;
        margin-bottom: 6px;
    }

    .enrolledTabMainContainer .e_row .payBtnContainer {
        font-size: 14px;
    }

    .enrolledTabMainContainer .pdfHolder .div_01 .title {
        font-size: 12px;
    }
}