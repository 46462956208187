.contentDescriptionMainContainer {
	background: #e8f9ff;
	border-radius: 5px;
	margin-bottom: 20px;
	padding: 10px 5px 5px 10px;
	position: relative;
	margin-top: 10px;
}

.contentDescriptionMainContainer p {
	margin-bottom: 4px;
	font-size: 15px;
}

.contentDescriptionMainContainer ul {
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 15px;
}

.contentDescriptionMainContainer ul li {
	margin-bottom: 5px;
}

.contentDescriptionMainContainer ul li:before {
	content: '';
	display: inline-block;
	margin-right: 10px;
	margin-top: -3px;
	width: 20px;
}

.contentDescriptionMainContainer .infoIcon {
	padding: 10px;
	position: absolute;
	right: 0;
	top: 0;
	width: 45px;
}

@media (max-width: 768px) {
	.contentDescriptionMainContainer {
		margin-bottom: 30px;
	}
}
