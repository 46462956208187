.mainOfferPage2,
.mainOfferPage1 {
    margin: 2rem 10vw;
    max-width: 1340px;
    text-align: left;
}

.mainOfferPage1 .adContainer {
    background-color: #1D3762;
    color: white;
}

.mainOfferPage1 .adContainer .container1 {
    justify-content: space-between;
    align-items: center;
    padding-left: 3vw;
}

.mainOfferPage1 .adContainer .container1 .adDescriptionContainer {
    margin: 10px 0px;
}

.mainOfferPage1 .adContainer .container1 .adDescriptionContainer .p1 {
    margin-bottom: 0px;
    font-size: 1rem;
}

.mainOfferPage1 .adContainer .container1 .adDescriptionContainer .p2 {
    margin-bottom: 0px;
    font-size: 1.1rem;
    font-weight: 700;
}

.mainOfferPage1 .adContainer .container1 .adPriceContainer {
    text-align: center;
}

.mainOfferPage1 .adContainer .container1 .adPriceContainer p {
    margin-bottom: 5px;
}

.mainOfferPage1 .adContainer .container1 .adPriceContainer button {
    background: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    color: #1D3762;
    font-weight: 400;
    padding: 0.1vw 3vw;
}


.mainOfferPage2 .offerContainer {
    position: relative;
    margin-bottom: 40px;
}

.mainOfferPage2 .offerContainer .offerContainer1 {
    width: 60px;
    float: right;
    margin-bottom: -25px;
    position: relative;
    text-align: center;
}

.mainOfferPage2 .offerContainer .offerPercentageImg {
    width: 100%;
}


.mainOfferPage2 .offerContainer .offerDiscountPercentage {
    font-size: 1.2rem;
    width: 60px;
    color: white;
    font-weight: 1000;
    margin-top: -43px;
    position: absolute;
}


.mainOfferPage2 .offerContainer .offerDescription {
    background: #F0F0F0;
    padding: 39px 15px 15px 15px;
    margin-top: -9px;
    border-radius: 0px 0px 15px 15px
}

.mainOfferPage2 .offerContainer .offerDetails {
    position: absolute;
    top: 20px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    opacity: 1;
    display: none;
    color: white;
    padding: 2rem 1.3rem 2rem 2rem;
    transition: all 0.4s ease 0s
}

#emptyOffer {
    width: 50%;
}


@media (max-width: 1340px) {

    .mainOfferPage2,
    .mainOfferPage1 {
        margin: 2rem;
    }
}

@media (max-width: 768px) {
    .mainOfferPage2 {
        margin: 2rem 20px;
    }

    #emptyOffer {
        width: 100%;
    }

    .mainOfferPage1 {
        margin: 2rem 0px;
    }

    .mainOfferPage2 .offerContainer .offerDetails {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .mainOfferPage2 .offerContainer .offerDetails {
        font-size: 0.9rem;
    }

    .mainOfferPage1 .adContainer .container1 .adPriceContainer {
        text-align: left;
        margin-top: 32px;
    }

    .mainOfferPage1 .adContainer .container1 .adPriceContainer p{
        font-size: 1.2rem;
    }

    .mainOfferPage1 .adContainer .container1 .adDescriptionContainer p{
        font-size: 1.5rem !important;
    }
    
    .mainOfferPage1 .adContainer .container1 .adPriceContainer button{
        padding: 3px 50px;
    }

}