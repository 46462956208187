.claimCertificateMainContainer .heading {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.03em;
}

.claimCertificateMainContainer .headingRed {
    color: #FD3535;
}

.claimCertificateMainContainer .headingNavyBlue{
    color : #5e5e7f;
}

.claimCertificateMainContainer .headingGreen {
    color: #00B900;
}

.claimCertificateMainContainer .headingPink {
    color: #FD3581;
}


.claimCertificateMainContainer .subHeading {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.03em;
}

.claimCertificateMainContainer .certificateBtn {
    border-radius: 10px;
    border: 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.03em;
    padding: 7px 30px;
    color: white;
}

.claimCertificateMainContainer .certificateBtnGrayColor {
    background: #CACACA;
    cursor: not-allowed;
}

.claimCertificateMainContainer .certificateBtnBlueColor {
    background: #1573FE;
}