.offerStrachCardContainer {
    position: relative;
    width: 90%;
    height: 30px;
    margin-top: -50px;
}

.offerStrachCardContainer canvas {
    left: 0px;
}

.offerStrachCardContainer .fill {
    width: 100%;
    height: 100%;
}

.offerStrachCardContainer .secret {
    border-radius: 6px;
    background-color: #FAFAFA;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}

.offerStrachCardContainer .secret-success{
    background-color: #d1e7dd;
    border-color: #438567;
    cursor: none;
}

/* 
NOT IN USE 
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
} */