.deskTopImageCarouselContainer {
	display: block;
}

.mobileTabImageCarouselContainer {
	display: none;
	width: 334px;
}

.deskTopImageCarouselContainer .imageCarouselViewContainer {
	margin-bottom: 30px;
}

.deskTopImageCarouselContainer .imageCarouselImg,
.mobileTabImageCarouselContainer .carousel-inner {
	width: 100%;
	box-shadow: 0px 0px 25px 0px #0000001a;
	border-radius: 5px;
}

.mobileTabImageCarouselContainer .carousel-control-prev {
	margin-left: -24px;
}

.mobileTabImageCarouselContainer .carousel-control-next {
	margin-right: -24px;
}

.mobileTabImageCarouselContainer .carouselControlBtn img {
	width: 40px;
}

@media (max-width: 768px) {
	.deskTopImageCarouselContainer {
		display: none;
	}

	.mobileTabImageCarouselContainer {
		display: block;
		width: 280px;
	}
}
